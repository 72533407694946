@import url(https://fonts.googleapis.com/css2?family=Anton&display=swap);
@import url(https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@100;200;300;400;500;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: 'JetBrains Mono', monospace;
}

.boldC{
  color: white;
}

.adminPanel{
  margin-top: 5%;
  background-color: #00000054;
  padding: 50px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50px;
}

.HL{
  color: greenyellow;
}

.HL2{
   color: gold; 
}

@-webkit-keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.logo:hover {
  cursor: pointer;
}

@-webkit-keyframes popupAnimation {
  0% {
    opacity: 0;
    -webkit-transform: translate(-50%, -60%);
            transform: translate(-50%, -60%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
}

@keyframes popupAnimation {
  0% {
    opacity: 0;
    -webkit-transform: translate(-50%, -60%);
            transform: translate(-50%, -60%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
}

.closeNote {
  position: absolute;
  right: 0;
  top: 5%;
  color: rgba(255, 255, 255, 0.836);
  font-size: 20px;
  font-weight: bold;
  background-color: rgba(172, 67, 67, 0);
  width: 100px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  cursor: pointer;
  border: none;
  transition: -webkit-transform .2s ease-in-out;
  transition: transform .2s ease-in-out;
  transition: transform .2s ease-in-out, -webkit-transform .2s ease-in-out;
}

.closeNote:hover,
.closeNote:focus {
  color: rgb(178, 236, 139);
}

.formMain2 {
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 50%;
  left: 50%;
  z-index: 100000000000;
  background-color: rgba(0, 0, 0, 0.726);
}

.disable {
  cursor: not-allowed;
}

.page-loader {
  width: 100%;
  height: 100vh;
  background: #000000;
  z-index: 5000000000000000;
  position: fixed;
}

.page-loader .txt {
  color: #ffffff;
  text-align: center;
  top: 40%;
  position: relative;
  text-transform: uppercase;
  line-height: 1.5;
  z-index: 1;
  text-align: center;
  font-size: 20px;
}

.spinner {
  position: relative;
  top: 35%;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 100%;
  -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
  animation: sk-scaleout 1.0s infinite ease-in-out;
}

.loading-area {
  display: grid;
  place-items: center;
  height: 100vh;
}

.loader div {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-animation: animate 1.5s ease-in-out infinite;
          animation: animate 1.5s ease-in-out infinite;
  display: inline-block;
  margin: .5rem;
}

.loader div:nth-child(0) {
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.loader div:nth-child(1) {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}

.loader div:nth-child(2) {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}

.loader div:nth-child(3) {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}

.loader div:nth-child(4) {
  -webkit-animation-delay: 0.8s;
          animation-delay: 0.8s;
}

.loader div:nth-child(5) {
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}

.loader div:nth-child(6) {
  -webkit-animation-delay: 1.2s;
          animation-delay: 1.2s;
}

.loader div:nth-child(7) {
  -webkit-animation-delay: 1.4s;
          animation-delay: 1.4s;
}

@-webkit-keyframes animate {

  0%,
  100% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
    background-color: gold;
  }

  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
    background-color: #00ff88;
  }

  50% {
    -webkit-transform: scale(1);
            transform: scale(1);
    background-color: #04ff81;
  }
}

@keyframes animate {

  0%,
  100% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
    background-color: gold;
  }

  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
    background-color: #00ff88;
  }

  50% {
    -webkit-transform: scale(1);
            transform: scale(1);
    background-color: #04ff81;
  }
}

@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1.0);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
    opacity: 0;
  }
}

.socialIcon img:hover {
  cursor: pointer;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

::-webkit-scrollbar {
  width: 15px;
  display: none;
}

/* Track */
::-webkit-scrollbar-track {
  background: #3b3b3b;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(127, 241, 51);
}

@media screen and (max-width: 499px) {
  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 3rem;
    padding-left: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-width: 2px;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #d4b500, #176300);
    border-image-slice: 1;
    -webkit-backdrop-filter: blur(5px);
    background: rgb(107, 215, 254);
    background: linear-gradient(90deg, rgba(107, 215, 254, 0.07886904761904767) 0%, rgba(199, 35, 163, 0.07046568627450978) 100%);
    margin-bottom: 2%;
    margin-top: 7%;
  }
  
  .ticker-wrap .ticker {
    display: inline-block;
    height: 3rem;
    line-height: 3rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 40s;
  }
  
  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.3rem;
    font-size: 16px;
  }
  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 20px;
    color: white;
  }
  
  summary {
    font-size: 14px;
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: rgb(255, 255, 255);
    font-weight: 500;
    border: 1px solid rgba(255, 255, 255, 0.719);
    background-color: #2525258f;
  }
  
  details>summary::-webkit-details-marker {
    display: none;
  }
  
  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }
  
  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  .accordin {
    width: 100%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .faq__content {
    color: rgb(255, 255, 255);
    padding-bottom: 20px;
    text-align: left;
    font-size: 16px;
  }
  
  .submit{
    border: 2px solid white;
    background-color: #00000085;
    color: white;
    padding: 10px;
   }
  
  input{
    border: 2px solid white;
    background-color: #00000085;
    color: white;
    padding: 10px;
  } 
  .formMain-2 {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    z-index: 100000;
    display: flex;
    flex-flow: row nowrap;
    background-color: rgba(0, 0, 0, 0);
    -webkit-animation: popupAnimation 0.5s ease forwards;
            animation: popupAnimation 0.5s ease forwards;
    border-radius: 10px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .lds-grid {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .lds-grid div {
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #cef;
    -webkit-animation: lds-grid 1.2s linear infinite;
            animation: lds-grid 1.2s linear infinite;
  }

  .lds-grid div:nth-child(1) {
    top: 8px;
    left: 8px;
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
  }

  .lds-grid div:nth-child(2) {
    top: 8px;
    left: 32px;
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
  }

  .lds-grid div:nth-child(3) {
    top: 8px;
    left: 56px;
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
  }

  .lds-grid div:nth-child(4) {
    top: 32px;
    left: 8px;
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
  }

  .lds-grid div:nth-child(5) {
    top: 32px;
    left: 32px;
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
  }

  .lds-grid div:nth-child(6) {
    top: 32px;
    left: 56px;
    -webkit-animation-delay: -1.2s;
            animation-delay: -1.2s;
  }

  .lds-grid div:nth-child(7) {
    top: 56px;
    left: 8px;
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
  }

  .lds-grid div:nth-child(8) {
    top: 56px;
    left: 32px;
    -webkit-animation-delay: -1.2s;
            animation-delay: -1.2s;
  }

  .lds-grid div:nth-child(9) {
    top: 56px;
    left: 56px;
    -webkit-animation-delay: -1.6s;
            animation-delay: -1.6s;
  }

  @-webkit-keyframes lds-grid {

    0%,
    100% {
      opacity: 1;
    }

    50% {
      opacity: 0.5;
    }
  }

  @keyframes lds-grid {

    0%,
    100% {
      opacity: 1;
    }

    50% {
      opacity: 0.5;
    }
  }


  .success-message {
    color: #50ec30;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 22px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 16px;
    margin-bottom: 0;
  }

  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    z-index: 10000000;
    color: white;
  }

  .loadText {
    text-align: center;
    color: #0fce0f;
    font-size: 20px;
    z-index: 100000000000000000;
  }

  .loadingText-2 {
    text-align: center;
    color: white;
    font-size: 18px;
    z-index: 100000000000000000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 20px;
  }

  .error-2 {
    text-align: center;
    color: #fc4741;
    font-size: 18px;
    z-index: 100000000000000000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 20px;
  }

  .success-2 {
    text-align: center;
    color: #50ec30;
    font-size: 18px;
    z-index: 100000000000000000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 20px;
  }

  .check {
    width: 80px;
    height: 80px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 30px;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    /*background-color: #0000009f;*/
    z-index: 1000000;
    box-shadow: 0 0 10px 5px #00853c5d;
    border-bottom: 1px solid rgba(255, 255, 255, 0.507);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 16px;
    font-family: 'JetBrains Mono', 'monospace';
    cursor: pointer;
    z-index: 10000;
    color: #ffffff;
    text-shadow: 0 0 10px #FFFFFF;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }

  #fontSize:hover {
    text-shadow: 0 0 20px #ff462d;
    color: #e2f3a5;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 50%;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 45px;
    margin-right: 45px;
  }

  .connectBtn {
    background-color: rgba(0, 0, 0, 0.863);
    padding: 10px;
    font-size: 14px;
    padding-left: 10px;
    padding-right: 10px;
    color: white;
    text-shadow: 0 0 10px #FFFFFF;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
    border: 1px solid rgba(255, 255, 255, 0.507);
    font-family: 'JetBrains Mono', monospace;
    margin-left: 10px;
    box-shadow: 0 0 20px 5px rgba(255, 217, 0, 0.329);
    border: 1px solid;
    border-image: linear-gradient(to bottom, gold, #998f00);
    border-image-slice: 1;
  }

  .socialIcon {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .socialIcon img {
    width: 25px;
    height: 25px;
    margin-left: 8px;
    margin-right: 8px;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
  }

  .walletTxt {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  .wallet {
    width: 30px;
    height: 30px;
  }

  .connectBtn:hover {
    text-shadow: 0 0 20px #ffffff;
    color: #c6f52c;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;

    justify-content: center;
  }

  .cont {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.2909335609243697) 0%, rgba(0, 0, 0, 0.6970960259103641) 49%, rgba(0, 0, 0, 0.8567598914565826) 100%), url(/static/media/bg.e0255528.jpg) no-repeat center center;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.png') no-repeat center center fixed;*/
    background-size: cover;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
    /*animation: changeBackground 10s infinite;*/
    min-height: 100vh;
    height: max-content;
    background-size: cover;
  }

  .introduction {
    margin-bottom: 10vh;
    margin-top: 15vh;
  }

  .logo {
    width: 60px;
    height: 60px;
    border-radius: 100px;
    border: 1px rgba(255, 217, 0, 0.589) solid;
    box-shadow: 0 0 20px 5px rgba(255, 217, 0, 0.329);
  }


  .logoDiv {
    display: flex;
    justify-content: center;
  }

  .title {
    font-size: 55px;
    color: white;
    text-align: center;
    font-family: 'Anton', sans-serif;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    background: -webkit-linear-gradient(gold, #00ff88);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    line-height: 70px;
  }

  .subH {
    color: rgba(255, 255, 255, 0.671);
    text-align: center;
    font-size: 15px;
    text-shadow: 0 0 10px #00853c;
    white-space: nowrap;
    overflow: hidden;
    border-right: 4px solid rgba(255, 255, 255, .75);
    position: relative;
    margin-left: auto;
    margin-right: auto;
  }


  .line-1 {
    position: relative;
    color: rgb(255, 255, 255);
    font-size: 20px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    border-right: 2px solid rgba(255, 255, 255, .75);
    width: 100%;
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    font-size: 80px;
    color: white;
    font-weight: bold;
    letter-spacing: 3px;
  }

  .anim-typewriter {
    -webkit-animation: typewriter 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
            animation: typewriter 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
  }

  @-webkit-keyframes blinkTextCursor {
    from {
      border-right-color: rgba(255, 255, 255, .75);
    }

    to {
      border-right-color: transparent;
    }
  }

  @keyframes blinkTextCursor {
    from {
      border-right-color: rgba(255, 255, 255, .75);
    }

    to {
      border-right-color: transparent;
    }
  }

  @-webkit-keyframes typewriter {
    from {
      width: 0;
    }

    to {
      width: 330px;
    }
  }

  @keyframes typewriter {
    from {
      width: 0;
    }

    to {
      width: 330px;
    }
  }

  .introLogo {
    position: relative;
    border-radius: 100%;
    width: 30%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    opacity: 0.15;
    -webkit-animation: fadeAnimation 3s ease-in-out infinite alternate;
            animation: fadeAnimation 3s ease-in-out infinite alternate;
    /* Add animation property */
  }

  @-webkit-keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @-webkit-keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }


  .mintDiv {
    padding: 20px;
    padding-top: 50px;
    padding-bottom: 50px;
    width: 45%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    border: 2px solid rgba(255, 255, 255, 0.575);
    background-color: #21181dbb;
    border: 2px #faac98 solid;
    -webkit-animation-name: glow-in-out;
            animation-name: glow-in-out;
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    border-radius: 50px;
  }


  .mintCon {
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 2px;
    z-index: 10000000;
    font-weight: 500;
  }

  .price {
    text-align: center;
    font-size: 30px;
    color: rgb(255, 255, 255);
    z-index: 10000000;
    letter-spacing: 3px;
  }


  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    z-index: 10000000;
    width: 90%;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100000000000;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .btnfos-0-2 {
    font-size: 20px;
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 10px;
    z-index: 100000000;
    cursor: pointer;
    background-color: #3a9ae800;
    color: goldenrod;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 1px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
    color: white;
    border: #b1f11c 1px solid;
  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
  }

  .nftamount {
    color: goldenrod;
    font-size: 50px;
    font-family: 'Anton', sans-serif;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;
  }

  .success-message {
    color: #4caf50;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 30px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 22px;
    margin-bottom: 0;
  }


  .errorMessage {
    margin-top: 3%;
    font-size: 25px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 3%;
  }

  .loadingText {
    font-size: 25px;
    text-align: center;
    color: white;
    letter-spacing: 2px;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 20px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    -webkit-animation: dots 1s steps(5, end) infinite;
            animation: dots 1s steps(5, end) infinite;
  }

  @-webkit-keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .connectButton {
    font-size: 16px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    text-shadow: 0 0 10px #FFFFFF;
    color: rgb(255, 255, 255);
    background-color: rgba(255, 217, 0, 0);
    width: 270px;
    padding: 12px;
    border: 2px white solid;
    margin-top: 8%;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(15px);
    border: 1px solid gold;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;

  }

  .connectButton:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    box-shadow: 0 0 15px 10px #00853c62;
  }

  .connectButton2 {
    font-size: 16px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    text-shadow: 0 0 10px #FFFFFF;
    color: rgb(255, 255, 255);
    background-color: rgba(255, 217, 0, 0);
    width: 90%;
    padding: 15px;
    border: 2px white solid;
    margin-top: 8%;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(15px);
    border: 1px solid gold;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
    width: 100%;
  }

  .connectButton2:hover {
    box-shadow: 0 0 15px 10px #00853c62;
  }

  .connectButton2:active {
    background-color: #00853c3a;
  }

  @-webkit-keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .boxWrap2MintMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-top: 3%;
    padding-bottom: 5%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.13687193627450978) 0%, rgba(0, 0, 0, 0.5766478466386555) 49%, rgba(0, 0, 0, 0.8007374824929971) 100%), url('https://media.discordapp.net/attachments/1008571037862080542/1132577262965882911/NFTHouse9_coins_floating_cyber_punk_theme_greenish_and_yellow_84ff95a2-ed2c-47c4-b751-199a85841236.png?width=843&height=473') no-repeat center center;
    background-size: cover;
  }


  .boxWrap2Mint {
    display: flex;
    justify-content: center;
    margin-top: 10%;
    margin-bottom: 3%;
    flex-flow: column nowrap;
  }

  .box1 {
    background-color: white;
    margin-left: 30px;
    margin-right: 30px;
    background-color: #0005001f;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(5px);
    width: 95%;
    border: 1px solid rgba(255, 255, 255, 0.219);
    box-shadow: 0 0 10px 5px #83850062;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
  }

  .boxH {
    color: white;
    font-family: 'Anton', sans-serif;
    color: gold;
    font-size: 22px;
    text-align: center;
  }

  .boxH2 {
    display: flex;
    justify-content: space-evenly;
    color: gold;
    font-size: 22px;
    font-family: 'Anton', sans-serif;
  }

  .txtH2 {
    font-family: 'Anton', sans-serif;
  }

  .subBoxMain {
    padding: 30px;
  }

  .subBoxMain2 {
    display: flex;
    justify-content: space-evenly;
    color: white;
    margin-top: 5%;
  }

  .subBox {
    border: 1px #ffffff34 solid;
    padding: 20px;
    width: 220px;
  }

  .subBoxCon1 {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.486);
  }

  .subBoxCon2 {
    font-size: 18px;
  }

  .timeMain {
    display: flex;
    color: white;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 15%;
  }

  .time {
    font-size: 35px;
    color: gold;
    font-weight: 500;
    background-color: #0c3a0ea4;
    width: 70px;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .timeN {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.486);
  }

  .timeMain2 {
    margin-left: 15px;
    margin-right: 15px;
  }

  .subBox2 {
    display: flex;
    justify-content: space-evenly;
    color: white;
    border: 1px #ffffff34 solid;
    margin-top: 6%;
    margin-bottom: 6%;
  }

  .subBox3 {
    display: flex;
    justify-content: space-between;
    color: white;
    margin-top: 10px;
  }

  .subBoxCon2-1 {
    font-weight: bold;
    font-size: 16px;
  }


  .socialIcons {
    display: flex;
    margin-left: auto;
    margin-bottom: 4%;
  }

  .socialIcons img {
    margin-right: 20px;
    width: 30px;
    height: 30px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .socialIcons img:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    cursor: pointer;
  }

  .footer {
    justify-content: space-between;
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
  }

  .boxWrap2Footer {
    background-color: rgba(0, 0, 0, 0);
  }

  .footer1 {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-top: 5%;
  }

  .line2 {
    width: 100%;
    height: 0.8px;
    background-color: white;
    margin-bottom: 30px;
  }

  .copyright {
    text-align: left;
    color: rgba(255, 255, 255, 0.5);
    font-size: 12px;
    color: white;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .terms {
    display: flex;
  }

  .terms div {
    margin-left: 20px;
    color: rgba(255, 255, 255, 0.5);
    font-size: 12px;
    color: white;
    text-shadow: 0 0 10px #FFFFFF;
  }
}

@media screen and (min-width: 500px) and (max-width: 767px) {
  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    padding-left: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-width: 3px;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #d4b500, #176300);
    border-image-slice: 1;
    -webkit-backdrop-filter: blur(5px);
    background: rgb(107, 215, 254);
    background: linear-gradient(90deg, rgba(107, 215, 254, 0.07886904761904767) 0%, rgba(199, 35, 163, 0.07046568627450978) 100%);
    margin-bottom: 2%;
    margin-top: 7%;
  }
  
  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 40s;
  }
  
  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 18px;
  }
  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 20px;
    color: white;
  }
  
  summary {
    font-size: 14px;
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: rgb(255, 255, 255);
    font-weight: 500;
    border: 1px solid rgba(255, 255, 255, 0.719);
    background-color: #2525258f;
  }
  
  details>summary::-webkit-details-marker {
    display: none;
  }
  
  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }
  
  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  .accordin {
    width: 100%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .faq__content {
    color: rgb(255, 255, 255);
    padding-bottom: 20px;
    text-align: left;
    font-size: 16px;
  }
  
  .submit{
    border: 2px solid white;
    background-color: #00000085;
    color: white;
    padding: 10px;
   }
  
  input{
    border: 2px solid white;
    background-color: #00000085;
    color: white;
    padding: 10px;
  } 
  .formMain-2 {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    z-index: 100000;
    display: flex;
    flex-flow: row nowrap;
    background-color: rgba(0, 0, 0, 0);
    -webkit-animation: popupAnimation 0.5s ease forwards;
            animation: popupAnimation 0.5s ease forwards;
    border-radius: 10px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .lds-grid {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .lds-grid div {
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #cef;
    -webkit-animation: lds-grid 1.2s linear infinite;
            animation: lds-grid 1.2s linear infinite;
  }

  .lds-grid div:nth-child(1) {
    top: 8px;
    left: 8px;
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
  }

  .lds-grid div:nth-child(2) {
    top: 8px;
    left: 32px;
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
  }

  .lds-grid div:nth-child(3) {
    top: 8px;
    left: 56px;
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
  }

  .lds-grid div:nth-child(4) {
    top: 32px;
    left: 8px;
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
  }

  .lds-grid div:nth-child(5) {
    top: 32px;
    left: 32px;
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
  }

  .lds-grid div:nth-child(6) {
    top: 32px;
    left: 56px;
    -webkit-animation-delay: -1.2s;
            animation-delay: -1.2s;
  }

  .lds-grid div:nth-child(7) {
    top: 56px;
    left: 8px;
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
  }

  .lds-grid div:nth-child(8) {
    top: 56px;
    left: 32px;
    -webkit-animation-delay: -1.2s;
            animation-delay: -1.2s;
  }

  .lds-grid div:nth-child(9) {
    top: 56px;
    left: 56px;
    -webkit-animation-delay: -1.6s;
            animation-delay: -1.6s;
  }

  @-webkit-keyframes lds-grid {

    0%,
    100% {
      opacity: 1;
    }

    50% {
      opacity: 0.5;
    }
  }

  @keyframes lds-grid {

    0%,
    100% {
      opacity: 1;
    }

    50% {
      opacity: 0.5;
    }
  }


  .success-message {
    color: #50ec30;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 24px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 18px;
    margin-bottom: 0;
  }

  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    z-index: 10000000;
    color: white;
  }

  .loadText {
    text-align: center;
    color: #0fce0f;
    font-size: 20px;
    z-index: 100000000000000000;
  }

  .loadingText-2 {
    text-align: center;
    color: white;
    font-size: 18px;
    z-index: 100000000000000000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 20px;
  }

  .error-2 {
    text-align: center;
    color: #fc4741;
    font-size: 18px;
    z-index: 100000000000000000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 20px;
  }

  .success-2 {
    text-align: center;
    color: #50ec30;
    font-size: 18px;
    z-index: 100000000000000000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 20px;
  }

  .check {
    width: 100px;
    height: 100px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 30px;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    /*background-color: #0000009f;*/
    z-index: 1000000;
    box-shadow: 0 0 10px 5px #00853c5d;
    border-bottom: 1px solid rgba(255, 255, 255, 0.507);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 16px;
    font-family: 'JetBrains Mono', 'monospace';
    cursor: pointer;
    z-index: 10000;
    color: #ffffff;
    text-shadow: 0 0 10px #FFFFFF;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }

  #fontSize:hover {
    text-shadow: 0 0 20px #ff462d;
    color: #e2f3a5;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 50%;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 45px;
    margin-right: 45px;
  }

  .connectBtn {
    background-color: rgba(0, 0, 0, 0.863);
    padding: 10px;
    font-size: 14px;
    padding-left: 20px;
    padding-right: 20px;
    color: white;
    text-shadow: 0 0 10px #FFFFFF;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
    border: 1px solid rgba(255, 255, 255, 0.507);
    font-family: 'JetBrains Mono', monospace;
    margin-left: 10px;
    box-shadow: 0 0 20px 5px rgba(255, 217, 0, 0.329);
    border: 1px solid;
    border-image: linear-gradient(to bottom, gold, #998f00);
    border-image-slice: 1;
  }

  .socialIcon {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .socialIcon img {
    width: 25px;
    height: 25px;
    margin-left: 8px;
    margin-right: 8px;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
  }

  .walletTxt {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  .wallet {
    width: 30px;
    height: 30px;
  }

  .connectBtn:hover {
    text-shadow: 0 0 20px #ffffff;
    color: #c6f52c;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;

    justify-content: center;
  }

  .cont {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.2909335609243697) 0%, rgba(0, 0, 0, 0.6970960259103641) 49%, rgba(0, 0, 0, 0.8567598914565826) 100%), url(/static/media/bg.e0255528.jpg) no-repeat center center;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.png') no-repeat center center fixed;*/
    background-size: cover;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
    /*animation: changeBackground 10s infinite;*/
    min-height: 100vh;
    height: max-content;
    background-size: cover;
  }

  .introduction {
    margin-bottom: 10vh;
    margin-top: 20vh;
  }

  .logo {
    width: 70px;
    height: 70px;
    border-radius: 100px;
    border: 1px rgba(255, 217, 0, 0.589) solid;
    box-shadow: 0 0 20px 5px rgba(255, 217, 0, 0.329);
  }


  .logoDiv {
    display: flex;
    justify-content: center;
  }

  .title {
    font-size: 60px;
    color: white;
    text-align: center;
    font-family: 'Anton', sans-serif;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    background: -webkit-linear-gradient(gold, #00ff88);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .subH {
    color: rgba(255, 255, 255, 0.671);
    text-align: center;
    font-size: 18px;
    text-shadow: 0 0 10px #00853c;
    white-space: nowrap;
    overflow: hidden;
    border-right: 4px solid rgba(255, 255, 255, .75);
    position: relative;
    margin-left: auto;
    margin-right: auto;
  }


  .line-1 {
    position: relative;
    color: rgb(255, 255, 255);
    font-size: 20px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    border-right: 2px solid rgba(255, 255, 255, .75);
    width: 100%;
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    font-size: 80px;
    color: white;
    font-weight: bold;
    letter-spacing: 3px;
  }

  .anim-typewriter {
    -webkit-animation: typewriter 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
            animation: typewriter 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
  }

  @-webkit-keyframes blinkTextCursor {
    from {
      border-right-color: rgba(255, 255, 255, .75);
    }

    to {
      border-right-color: transparent;
    }
  }

  @keyframes blinkTextCursor {
    from {
      border-right-color: rgba(255, 255, 255, .75);
    }

    to {
      border-right-color: transparent;
    }
  }

  @-webkit-keyframes typewriter {
    from {
      width: 0;
    }

    to {
      width: 400px;
    }
  }

  @keyframes typewriter {
    from {
      width: 0;
    }

    to {
      width: 400px;
    }
  }

  .introLogo {
    position: relative;
    border-radius: 100%;
    width: 30%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    opacity: 0.15;
    -webkit-animation: fadeAnimation 3s ease-in-out infinite alternate;
            animation: fadeAnimation 3s ease-in-out infinite alternate;
    /* Add animation property */
  }

  @-webkit-keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @-webkit-keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }


  .mintDiv {
    padding: 20px;
    padding-top: 50px;
    padding-bottom: 50px;
    width: 45%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    border: 2px solid rgba(255, 255, 255, 0.575);
    background-color: #21181dbb;
    border: 2px #faac98 solid;
    -webkit-animation-name: glow-in-out;
            animation-name: glow-in-out;
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    border-radius: 50px;
  }


  .mintCon {
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 2px;
    z-index: 10000000;
    font-weight: 500;
  }

  .price {
    text-align: center;
    font-size: 30px;
    color: rgb(255, 255, 255);
    z-index: 10000000;
    letter-spacing: 3px;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    z-index: 10000000;
    width: 90%;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100000000000;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .btnfos-0-2 {
    font-size: 20px;
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 10px;
    z-index: 100000000;
    cursor: pointer;
    background-color: #3a9ae800;
    color: goldenrod;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 1px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
    color: white;
    border: #b1f11c 1px solid;
  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
  }

  .nftamount {
    color: goldenrod;
    font-size: 50px;
    font-family: 'Anton', sans-serif;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;
  }

  .connectButton {
    font-size: 16px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    text-shadow: 0 0 10px #FFFFFF;
    color: rgb(255, 255, 255);
    background-color: rgba(255, 217, 0, 0);
    width: 300px;
    padding: 15px;
    border: 2px white solid;
    margin-top: 8%;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(15px);
    border: 1px solid gold;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;

  }

  .connectButton:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    box-shadow: 0 0 15px 10px #00853c62;
  }

  .connectButton2 {
    font-size: 18px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    text-shadow: 0 0 10px #FFFFFF;
    color: rgb(255, 255, 255);
    background-color: rgba(255, 217, 0, 0);
    width: 350px;
    padding: 15px;
    border: 2px white solid;
    margin-top: 8%;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(15px);
    border: 1px solid gold;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
    width: 100%;
  }

  .connectButton2:hover {
    box-shadow: 0 0 15px 10px #00853c62;
  }

  .connectButton2:active {
    background-color: #00853c3a;
  }

  @-webkit-keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .boxWrap2MintMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-top: 3%;
    padding-bottom: 5%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.13687193627450978) 0%, rgba(0, 0, 0, 0.5766478466386555) 49%, rgba(0, 0, 0, 0.8007374824929971) 100%), url('https://media.discordapp.net/attachments/1008571037862080542/1132577262965882911/NFTHouse9_coins_floating_cyber_punk_theme_greenish_and_yellow_84ff95a2-ed2c-47c4-b751-199a85841236.png?width=843&height=473') no-repeat center center;
    background-size: cover;
  }


  .boxWrap2Mint {
    display: flex;
    justify-content: center;
    margin-top: 10%;
    margin-bottom: 3%;
    flex-flow: column nowrap;
  }

  .box1 {
    background-color: white;
    margin-left: 30px;
    margin-right: 30px;
    background-color: #0005001f;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(5px);
    width: 95%;
    border: 1px solid rgba(255, 255, 255, 0.219);
    box-shadow: 0 0 10px 5px #83850062;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
  }

  .boxH {
    color: white;
    font-family: 'Anton', sans-serif;
    color: gold;
    font-size: 25px;
    text-align: center;
  }

  .boxH2 {
    display: flex;
    justify-content: space-evenly;
    color: gold;
    font-size: 25px;
    font-family: 'Anton', sans-serif;
  }

  .txtH2 {
    font-family: 'Anton', sans-serif;
  }

  .subBoxMain {
    padding: 30px;
  }

  .subBoxMain2 {
    display: flex;
    justify-content: space-evenly;
    color: white;
    margin-top: 5%;
  }

  .subBox {
    border: 1px #ffffff34 solid;
    padding: 20px;
    width: 220px;
  }

  .subBoxCon1 {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.486);
  }

  .subBoxCon2 {
    font-size: 18px;
  }

  .timeMain {
    display: flex;
    color: white;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 15%;
  }

  .time {
    font-size: 35px;
    color: gold;
    font-weight: 500;
    background-color: #0c3a0ea4;
    width: 100px;
    border-radius: 10px;
  }

  .timeN {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.486);
  }

  .timeMain2 {
    margin-left: 20px;
    margin-right: 20px;
  }

  .subBox2 {
    display: flex;
    justify-content: space-evenly;
    color: white;
    border: 1px #ffffff34 solid;
    margin-top: 6%;
    margin-bottom: 6%;
  }

  .subBox3 {
    display: flex;
    justify-content: space-between;
    color: white;
    margin-top: 10px;
  }

  .subBoxCon2-1 {
    font-weight: bold;
    font-size: 16px;
  }


  .socialIcons {
    display: flex;
    margin-left: auto;
    margin-bottom: 4%;
  }

  .socialIcons img {
    margin-right: 20px;
    width: 30px;
    height: 30px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .socialIcons img:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    cursor: pointer;
  }

  .footer {
    justify-content: space-between;
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
  }

  .boxWrap2Footer {
    background-color: rgba(0, 0, 0, 0);
  }

  .footer1 {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-top: 5%;
  }

  .line2 {
    width: 100%;
    height: 0.8px;
    background-color: white;
    margin-bottom: 30px;
  }

  .copyright {
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
    font-size: 12px;
    color: white;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .terms {
    display: flex;
  }

  .terms div {
    margin-left: 20px;
    color: rgba(255, 255, 255, 0.5);
    font-size: 12px;
    color: white;
    text-shadow: 0 0 10px #FFFFFF;
  }

}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    padding-left: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-width: 3px;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #d4b500, #176300);
    border-image-slice: 1;
    -webkit-backdrop-filter: blur(5px);
    background: rgb(107, 215, 254);
    background: linear-gradient(90deg, rgba(107, 215, 254, 0.07886904761904767) 0%, rgba(199, 35, 163, 0.07046568627450978) 100%);
    margin-bottom: 2%;
    margin-top: 2%;
  }
  
  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 40s;
  }
  
  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 18px;
  }
  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 20px;
    color: white;
  }
  
  summary {
    font-size: 14px;
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: rgb(255, 255, 255);
    font-weight: 500;
    border: 1px solid rgba(255, 255, 255, 0.719);
    background-color: #2525258f;
  }
  
  details>summary::-webkit-details-marker {
    display: none;
  }
  
  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }
  
  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .faq__content {
    color: rgb(255, 255, 255);
    padding-bottom: 20px;
    text-align: left;
    font-size: 16px;
  }
  
  .submit{
    border: 2px solid white;
    background-color: #00000085;
    color: white;
    padding: 10px;
   }
  
  input{
    border: 2px solid white;
    background-color: #00000085;
    color: white;
    padding: 10px;
  } 
  .formMain-2 {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    z-index: 100000;
    display: flex;
    flex-flow: row nowrap;
    background-color: rgba(0, 0, 0, 0);
    -webkit-animation: popupAnimation 0.5s ease forwards;
            animation: popupAnimation 0.5s ease forwards;
    border-radius: 10px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }

  .lds-grid {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .lds-grid div {
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #cef;
    -webkit-animation: lds-grid 1.2s linear infinite;
            animation: lds-grid 1.2s linear infinite;
  }

  .lds-grid div:nth-child(1) {
    top: 8px;
    left: 8px;
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
  }

  .lds-grid div:nth-child(2) {
    top: 8px;
    left: 32px;
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
  }

  .lds-grid div:nth-child(3) {
    top: 8px;
    left: 56px;
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
  }

  .lds-grid div:nth-child(4) {
    top: 32px;
    left: 8px;
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
  }

  .lds-grid div:nth-child(5) {
    top: 32px;
    left: 32px;
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
  }

  .lds-grid div:nth-child(6) {
    top: 32px;
    left: 56px;
    -webkit-animation-delay: -1.2s;
            animation-delay: -1.2s;
  }

  .lds-grid div:nth-child(7) {
    top: 56px;
    left: 8px;
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
  }

  .lds-grid div:nth-child(8) {
    top: 56px;
    left: 32px;
    -webkit-animation-delay: -1.2s;
            animation-delay: -1.2s;
  }

  .lds-grid div:nth-child(9) {
    top: 56px;
    left: 56px;
    -webkit-animation-delay: -1.6s;
            animation-delay: -1.6s;
  }

  @-webkit-keyframes lds-grid {

    0%,
    100% {
      opacity: 1;
    }

    50% {
      opacity: 0.5;
    }
  }

  @keyframes lds-grid {

    0%,
    100% {
      opacity: 1;
    }

    50% {
      opacity: 0.5;
    }
  }


  .success-message {
    color: #50ec30;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 24px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 18px;
    margin-bottom: 0;
  }

  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    z-index: 10000000;
    color: white;
  }

  .loadText {
    text-align: center;
    color: #0fce0f;
    font-size: 20px;
    z-index: 100000000000000000;
  }

  .loadingText-2 {
    text-align: center;
    color: white;
    font-size: 18px;
    z-index: 100000000000000000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 20px;
  }

  .error-2 {
    text-align: center;
    color: #fc4741;
    font-size: 18px;
    z-index: 100000000000000000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 20px;
  }

  .success-2 {
    text-align: center;
    color: #50ec30;
    font-size: 18px;
    z-index: 100000000000000000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 20px;
  }

  .check {
    width: 100px;
    height: 100px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 30px;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    /*background-color: #0000009f;*/
    z-index: 1000000;
    box-shadow: 0 0 10px 5px #00853c5d;
    border-bottom: 1px solid rgba(255, 255, 255, 0.507);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);

  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 16px;
    font-family: 'JetBrains Mono', 'monospace';
    cursor: pointer;
    z-index: 10000;
    color: #ffffff;
    text-shadow: 0 0 10px #FFFFFF;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }

  #fontSize:hover {
    text-shadow: 0 0 20px #ff462d;
    color: #e2f3a5;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 50%;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 45px;
    margin-right: 45px;
  }

  .connectBtn {
    background-color: rgba(0, 0, 0, 0.863);
    padding: 10px;
    font-size: 16px;
    padding-left: 30px;
    padding-right: 30px;
    color: white;
    text-shadow: 0 0 10px #FFFFFF;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
    border: 1px solid rgba(255, 255, 255, 0.507);
    font-family: 'JetBrains Mono', monospace;
    margin-left: 20px;
    box-shadow: 0 0 20px 5px rgba(255, 217, 0, 0.329);
    border: 1px solid;
    border-image: linear-gradient(to bottom, gold, #998f00);
    border-image-slice: 1;
  }

  .socialIcon {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .socialIcon img {
    width: 28px;
    height: 28px;
    margin-left: 8px;
    margin-right: 8px;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
  }

  .walletTxt {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  .wallet {
    width: 30px;
    height: 30px;
  }

  .connectBtn:hover {
    text-shadow: 0 0 20px #ffffff;
    color: #c6f52c;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;

    justify-content: center;
  }

  .cont {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.2909335609243697) 0%, rgba(0, 0, 0, 0.6970960259103641) 49%, rgba(0, 0, 0, 0.8567598914565826) 100%), url(/static/media/bg.e0255528.jpg) no-repeat center center;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.png') no-repeat center center fixed;*/
    background-size: cover;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
    /*animation: changeBackground 10s infinite;*/
    min-height: 100vh;
    height: max-content;
    background-size: cover;
  }

  .introduction {
    margin-bottom: 10vh;
    margin-top: 20vh;
  }

  .logo {
    width: 80px;
    height: 80px;
    border-radius: 100px;
    border: 1px rgba(255, 217, 0, 0.589) solid;
    box-shadow: 0 0 20px 5px rgba(255, 217, 0, 0.329);
  }


  .logoDiv {
    display: flex;
    justify-content: center;
  }

  .title {
    font-size: 80px;
    color: white;
    text-align: center;
    font-family: 'Anton', sans-serif;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    background: -webkit-linear-gradient(gold, #00ff88);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .subH {
    color: rgba(255, 255, 255, 0.671);
    text-align: center;
    margin-top: -10px;
    font-size: 20px;
    text-shadow: 0 0 10px #00853c;
    white-space: nowrap;
    overflow: hidden;
    border-right: 4px solid rgba(255, 255, 255, .75);
    position: relative;
    margin-left: auto;
    margin-right: auto;
  }


  .line-1 {
    position: relative;
    color: rgb(255, 255, 255);
    font-size: 20px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    border-right: 2px solid rgba(255, 255, 255, .75);
    width: 100%;
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    font-size: 80px;
    color: white;
    font-weight: bold;
    letter-spacing: 3px;
  }

  .anim-typewriter {
    -webkit-animation: typewriter 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
            animation: typewriter 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
  }

  @-webkit-keyframes blinkTextCursor {
    from {
      border-right-color: rgba(255, 255, 255, .75);
    }

    to {
      border-right-color: transparent;
    }
  }

  @keyframes blinkTextCursor {
    from {
      border-right-color: rgba(255, 255, 255, .75);
    }

    to {
      border-right-color: transparent;
    }
  }

  @-webkit-keyframes typewriter {
    from {
      width: 0;
    }

    to {
      width: 440px;
    }
  }

  @keyframes typewriter {
    from {
      width: 0;
    }

    to {
      width: 440px;
    }
  }

  .introLogo {
    position: relative;
    border-radius: 100%;
    width: 30%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    opacity: 0.15;
    -webkit-animation: fadeAnimation 3s ease-in-out infinite alternate;
            animation: fadeAnimation 3s ease-in-out infinite alternate;
    /* Add animation property */
  }

  @-webkit-keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @-webkit-keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }


  .mintDiv {
    padding: 20px;
    padding-top: 50px;
    padding-bottom: 50px;
    width: 45%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    border: 2px solid rgba(255, 255, 255, 0.575);
    background-color: #21181dbb;
    border: 2px #faac98 solid;
    -webkit-animation-name: glow-in-out;
            animation-name: glow-in-out;
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    border-radius: 50px;
  }


  .mintCon {
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 2px;
    z-index: 10000000;
    font-weight: 500;
  }

  .price {
    text-align: center;
    font-size: 30px;
    color: rgb(255, 255, 255);
    z-index: 10000000;
    letter-spacing: 3px;
  }


  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    z-index: 10000000;
    width: 90%;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100000000000;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .btnfos-0-2 {
    font-size: 20px;
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 10px;
    z-index: 100000000;
    cursor: pointer;
    background-color: #3a9ae800;
    color: goldenrod;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 1px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
    color: white;
    border: #b1f11c 1px solid;
  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
  }

  .nftamount {
    color: goldenrod;
    font-size: 50px;
    font-family: 'Anton', sans-serif;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;
  }

  .connectButton {
    font-size: 16px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    text-shadow: 0 0 10px #FFFFFF;
    color: rgb(255, 255, 255);
    background-color: rgba(255, 217, 0, 0);
    width: 300px;
    padding: 15px;
    border: 2px white solid;
    margin-top: 8%;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(15px);
    border: 1px solid gold;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;

  }

  .connectButton:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    box-shadow: 0 0 15px 10px #00853c62;
  }

  .connectButton2 {
    font-size: 18px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    text-shadow: 0 0 10px #FFFFFF;
    color: rgb(255, 255, 255);
    background-color: rgba(255, 217, 0, 0);
    width: 350px;
    padding: 15px;
    border: 2px white solid;
    margin-top: 8%;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(15px);
    border: 1px solid gold;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
    width: 100%;
  }

  .connectButton2:hover {
    box-shadow: 0 0 15px 10px #00853c62;
  }

  .connectButton2:active {
    background-color: #00853c3a;
  }

  @-webkit-keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .boxWrap2MintMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-top: 3%;
    padding-bottom: 5%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.13687193627450978) 0%, rgba(0, 0, 0, 0.5766478466386555) 49%, rgba(0, 0, 0, 0.8007374824929971) 100%), url('https://media.discordapp.net/attachments/1008571037862080542/1132577262965882911/NFTHouse9_coins_floating_cyber_punk_theme_greenish_and_yellow_84ff95a2-ed2c-47c4-b751-199a85841236.png?width=843&height=473') no-repeat center center;
    background-size: cover;
  }


  .boxWrap2Mint {
    display: flex;
    justify-content: center;
    margin-top: 3%;
    margin-bottom: 3%;
    flex-flow: column nowrap;
  }

  .box1 {
    background-color: white;
    margin-left: 30px;
    margin-right: 30px;
    background-color: #0005001f;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(5px);
    width: 80%;
    border: 1px solid rgba(255, 255, 255, 0.219);
    box-shadow: 0 0 10px 5px #83850062;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
  }

  .boxH {
    color: white;
    font-family: 'Anton', sans-serif;
    color: gold;
    font-size: 25px;
    text-align: center;
  }

  .boxH2 {
    display: flex;
    justify-content: space-evenly;
    color: gold;
    font-size: 25px;
    font-family: 'Anton', sans-serif;
  }

  .txtH2 {
    font-family: 'Anton', sans-serif;
  }

  .subBoxMain {
    padding: 30px;
  }

  .subBoxMain2 {
    display: flex;
    justify-content: space-evenly;
    color: white;
    margin-top: 5%;
  }

  .subBox {
    border: 1px #ffffff34 solid;
    padding: 20px;
    width: 220px;
  }

  .subBoxCon1 {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.486);
  }

  .subBoxCon2 {
    font-size: 18px;
  }

  .timeMain {
    display: flex;
    color: white;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 15%;
  }

  .time {
    font-size: 45px;
    color: gold;
    font-weight: 500;
    background-color: #0c3a0ea4;
    width: 130px;
    border-radius: 10px;
  }

  .timeN {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.486);
  }

  .timeMain2 {
    margin-left: 20px;
    margin-right: 20px;
  }

  .subBox2 {
    display: flex;
    justify-content: space-evenly;
    color: white;
    border: 1px #ffffff34 solid;
    margin-top: 6%;
    margin-bottom: 6%;
  }

  .subBox3 {
    display: flex;
    justify-content: space-between;
    color: white;
    margin-top: 10px;
  }

  .subBoxCon2-1 {
    font-weight: bold;
    font-size: 16px;
  }


  .socialIcons {
    display: flex;
    margin-left: auto;
    margin-bottom: 4%;
  }

  .socialIcons img {
    margin-right: 20px;
    width: 30px;
    height: 30px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .socialIcons img:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    cursor: pointer;
  }

  .footer {
    justify-content: space-between;
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
  }

  .boxWrap2Footer {
    background-color: rgba(0, 0, 0, 0);
  }

  .footer1 {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-top: 5%;
  }

  .line2 {
    width: 100%;
    height: 1.5px;
    background-color: white;
    margin-bottom: 30px;
  }

  .copyright {
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
    font-size: 15px;
    color: white;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .terms {
    display: flex;
  }

  .terms div {
    margin-left: 20px;
    color: rgba(255, 255, 255, 0.5);
    font-size: 15px;
    color: white;
    text-shadow: 0 0 10px #FFFFFF;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    padding-left: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-width: 3px;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #d4b500, #176300);
    border-image-slice: 1;
    -webkit-backdrop-filter: blur(5px);
    background: rgb(107, 215, 254);
    background: linear-gradient(90deg, rgba(107, 215, 254, 0.07886904761904767) 0%, rgba(199, 35, 163, 0.07046568627450978) 100%);
    margin-bottom: 2%;
    margin-top: 2%;
  }
  
  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 40s;
  }
  
  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 18px;
  }
  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 20px;
    color: white;
  }
  
  summary {
    font-size: 14px;
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: rgb(255, 255, 255);
    font-weight: 500;
    border: 1px solid rgba(255, 255, 255, 0.719);
    background-color: #2525258f;
  }
  
  details>summary::-webkit-details-marker {
    display: none;
  }
  
  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }
  
  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .faq__content {
    color: rgb(255, 255, 255);
    padding-bottom: 20px;
    text-align: left;
    font-size: 16px;
  }
  
  .submit{
    border: 2px solid white;
    background-color: #00000085;
    color: white;
    padding: 10px;
   }
  
  input{
    border: 2px solid white;
    background-color: #00000085;
    color: white;
    padding: 10px;
  } 
  .formMain-2 {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    z-index: 100000;
    display: flex;
    flex-flow: row nowrap;
    background-color: rgba(0, 0, 0, 0);
    padding: 50px;
    -webkit-animation: popupAnimation 0.5s ease forwards;
            animation: popupAnimation 0.5s ease forwards;
    border-radius: 10px;
  }

  .lds-grid {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .lds-grid div {
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #cef;
    -webkit-animation: lds-grid 1.2s linear infinite;
            animation: lds-grid 1.2s linear infinite;
  }

  .lds-grid div:nth-child(1) {
    top: 8px;
    left: 8px;
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
  }

  .lds-grid div:nth-child(2) {
    top: 8px;
    left: 32px;
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
  }

  .lds-grid div:nth-child(3) {
    top: 8px;
    left: 56px;
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
  }

  .lds-grid div:nth-child(4) {
    top: 32px;
    left: 8px;
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
  }

  .lds-grid div:nth-child(5) {
    top: 32px;
    left: 32px;
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
  }

  .lds-grid div:nth-child(6) {
    top: 32px;
    left: 56px;
    -webkit-animation-delay: -1.2s;
            animation-delay: -1.2s;
  }

  .lds-grid div:nth-child(7) {
    top: 56px;
    left: 8px;
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
  }

  .lds-grid div:nth-child(8) {
    top: 56px;
    left: 32px;
    -webkit-animation-delay: -1.2s;
            animation-delay: -1.2s;
  }

  .lds-grid div:nth-child(9) {
    top: 56px;
    left: 56px;
    -webkit-animation-delay: -1.6s;
            animation-delay: -1.6s;
  }

  @-webkit-keyframes lds-grid {

    0%,
    100% {
      opacity: 1;
    }

    50% {
      opacity: 0.5;
    }
  }

  @keyframes lds-grid {

    0%,
    100% {
      opacity: 1;
    }

    50% {
      opacity: 0.5;
    }
  }


  .success-message {
    color: #50ec30;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 24px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 18px;
    margin-bottom: 0;
  }

  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    z-index: 10000000;
    color: white;
  }

  .loadText {
    text-align: center;
    color: #0fce0f;
    font-size: 20px;
    z-index: 100000000000000000;
  }

  .loadingText-2 {
    text-align: center;
    color: white;
    font-size: 18px;
    z-index: 100000000000000000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 20px;
  }

  .error-2 {
    text-align: center;
    color: #fc4741;
    font-size: 18px;
    z-index: 100000000000000000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 20px;
  }

  .success-2 {
    text-align: center;
    color: #50ec30;
    font-size: 18px;
    z-index: 100000000000000000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 20px;
  }

  .check {
    width: 100px;
    height: 100px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 30px;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    /*background-color: #0000009f;*/
    z-index: 1000000;
    box-shadow: 0 0 10px 5px #00853c5d;
    border-bottom: 1px solid rgba(255, 255, 255, 0.507);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);

  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 16px;
    font-family: 'JetBrains Mono', 'monospace';
    cursor: pointer;
    z-index: 10000;
    color: #ffffff;
    text-shadow: 0 0 10px #FFFFFF;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }

  #fontSize:hover {
    text-shadow: 0 0 20px #ff462d;
    color: #e2f3a5;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 50%;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 45px;
    margin-right: 45px;
  }

  .connectBtn {
    background-color: rgba(0, 0, 0, 0.863);
    padding: 10px;
    font-size: 16px;
    padding-left: 30px;
    padding-right: 30px;
    color: white;
    text-shadow: 0 0 10px #FFFFFF;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
    border: 1px solid rgba(255, 255, 255, 0.507);
    font-family: 'JetBrains Mono', monospace;
    margin-left: 20px;
    box-shadow: 0 0 20px 5px rgba(255, 217, 0, 0.329);
    border: 1px solid;
    border-image: linear-gradient(to bottom, gold, #998f00);
    border-image-slice: 1;
  }

  .socialIcon {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .socialIcon img {
    width: 28px;
    height: 28px;
    margin-left: 8px;
    margin-right: 8px;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
  }

  .walletTxt {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  .wallet {
    width: 30px;
    height: 30px;
  }

  .connectBtn:hover {
    text-shadow: 0 0 20px #ffffff;
    color: #c6f52c;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;

    justify-content: center;
  }

  .cont {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.2909335609243697) 0%, rgba(0, 0, 0, 0.6970960259103641) 49%, rgba(0, 0, 0, 0.8567598914565826) 100%), url(/static/media/bg.e0255528.jpg) no-repeat center center fixed;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.png') no-repeat center center fixed;*/
    background-size: cover;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
    /*animation: changeBackground 10s infinite;*/
    min-height: 100vh;
    height: max-content;
    background-size: cover;
  }

  .introduction {
    margin-bottom: 10vh;
    margin-top: 10vh;
  }

  .logo {
    width: 80px;
    height: 80px;
    border-radius: 100px;
    border: 1px rgba(255, 217, 0, 0.589) solid;
    box-shadow: 0 0 20px 5px rgba(255, 217, 0, 0.329);
  }


  .logoDiv {
    display: flex;
    justify-content: center;
  }

  .title {
    font-size: 100px;
    color: white;
    text-align: center;
    font-family: 'Anton', sans-serif;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    background: -webkit-linear-gradient(gold, #00ff88);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .subH {
    color: rgba(255, 255, 255, 0.671);
    text-align: center;
    margin-top: -10px;
    font-size: 20px;
    text-shadow: 0 0 10px #00853c;
    white-space: nowrap;
    overflow: hidden;
    border-right: 4px solid rgba(255, 255, 255, .75);
    position: relative;
    margin-left: auto;
    margin-right: auto;
  }


  .line-1 {
    position: relative;
    color: rgb(255, 255, 255);
    font-size: 20px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    border-right: 2px solid rgba(255, 255, 255, .75);
    width: 100%;
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    font-size: 80px;
    color: white;
    font-weight: bold;
    letter-spacing: 3px;
  }

  .anim-typewriter {
    -webkit-animation: typewriter 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
            animation: typewriter 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
  }

  @-webkit-keyframes blinkTextCursor {
    from {
      border-right-color: rgba(255, 255, 255, .75);
    }

    to {
      border-right-color: transparent;
    }
  }

  @keyframes blinkTextCursor {
    from {
      border-right-color: rgba(255, 255, 255, .75);
    }

    to {
      border-right-color: transparent;
    }
  }

  @-webkit-keyframes typewriter {
    from {
      width: 0;
    }

    to {
      width: 440px;
    }
  }

  @keyframes typewriter {
    from {
      width: 0;
    }

    to {
      width: 440px;
    }
  }

  .introLogo {
    position: relative;
    border-radius: 100%;
    width: 30%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    opacity: 0.15;
    -webkit-animation: fadeAnimation 3s ease-in-out infinite alternate;
            animation: fadeAnimation 3s ease-in-out infinite alternate;
    /* Add animation property */
  }

  @-webkit-keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @-webkit-keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }


  .mintDiv {
    padding: 20px;
    padding-top: 50px;
    padding-bottom: 50px;
    width: 45%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    border: 2px solid rgba(255, 255, 255, 0.575);
    background-color: #21181dbb;
    border: 2px #faac98 solid;
    -webkit-animation-name: glow-in-out;
            animation-name: glow-in-out;
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    border-radius: 50px;
  }


  .mintCon {
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 2px;
    z-index: 10000000;
    font-weight: 500;
  }

  .price {
    text-align: center;
    font-size: 30px;
    color: rgb(255, 255, 255);
    z-index: 10000000;
    letter-spacing: 3px;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    z-index: 10000000;
    width: 90%;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100000000000;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .btnfos-0-2 {
    font-size: 20px;
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 10px;
    z-index: 100000000;
    cursor: pointer;
    background-color: #3a9ae800;
    color: goldenrod;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 1px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
    color: white;
    border: #b1f11c 1px solid;
  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
  }

  .nftamount {
    color: goldenrod;
    font-size: 50px;
    font-family: 'Anton', sans-serif;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;
  }



  .connectButton {
    font-size: 18px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    text-shadow: 0 0 10px #FFFFFF;
    color: rgb(255, 255, 255);
    background-color: rgba(255, 217, 0, 0);
    width: 350px;
    padding: 15px;
    border: 2px white solid;
    margin-top: 8%;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(15px);
    border: 1px solid gold;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;

  }

  .connectButton:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    box-shadow: 0 0 15px 10px #00853c62;
  }

  .connectButton2 {
    font-size: 18px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    text-shadow: 0 0 10px #FFFFFF;
    color: rgb(255, 255, 255);
    background-color: rgba(255, 217, 0, 0);
    width: 350px;
    padding: 15px;
    border: 2px white solid;
    margin-top: 8%;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(15px);
    border: 1px solid gold;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
    width: 100%;
  }

  .connectButton2:hover {
    box-shadow: 0 0 15px 10px #00853c62;
  }

  .connectButton2:active {
    background-color: #00853c3a;
  }

  @-webkit-keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .boxWrap2MintMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-top: 3%;
    padding-bottom: 5%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.13687193627450978) 0%, rgba(0, 0, 0, 0.5766478466386555) 49%, rgba(0, 0, 0, 0.8007374824929971) 100%), url('https://media.discordapp.net/attachments/1008571037862080542/1132577262965882911/NFTHouse9_coins_floating_cyber_punk_theme_greenish_and_yellow_84ff95a2-ed2c-47c4-b751-199a85841236.png?width=843&height=473') no-repeat center center fixed;
    background-size: cover;
  }


  .boxWrap2Mint {
    display: flex;
    justify-content: center;
    margin-top: 3%;
    margin-bottom: 3%;
  }

  .box1 {
    background-color: white;
    margin-left: 30px;
    margin-right: 30px;
    background-color: #0005001f;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(5px);
    width: 45%;
    border: 1px solid rgba(255, 255, 255, 0.219);
    box-shadow: 0 0 10px 5px #83850062;
  }

  .boxH {
    color: white;
    font-family: 'Anton', sans-serif;
    color: gold;
    font-size: 25px;
    text-align: center;
  }

  .boxH2 {
    display: flex;
    justify-content: space-evenly;
    color: gold;
    font-size: 25px;
    font-family: 'Anton', sans-serif;
  }

  .txtH2 {
    font-family: 'Anton', sans-serif;
  }

  .subBoxMain {
    padding: 30px;
  }

  .subBoxMain2 {
    display: flex;
    justify-content: space-evenly;
    color: white;
    margin-top: 5%;
  }

  .subBox {
    border: 1px #ffffff34 solid;
    padding: 20px;
    width: 220px;
  }

  .subBoxCon1 {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.486);
  }

  .subBoxCon2 {
    font-size: 18px;
  }

  .timeMain {
    display: flex;
    color: white;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 15%;
  }

  .time {
    font-size: 40px;
    color: gold;
    font-weight: 500;
    background-color: #0c3a0ea4;
    width: 110px;
    border-radius: 10px;
  }

  .timeN {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.486);
  }

  .timeMain2 {
    margin-left: 20px;
    margin-right: 20px;
  }

  .subBox2 {
    display: flex;
    justify-content: space-evenly;
    color: white;
    border: 1px #ffffff34 solid;
    margin-top: 6%;
    margin-bottom: 6%;
  }

  .subBox3 {
    display: flex;
    justify-content: space-between;
    color: white;
    margin-top: 10px;
  }

  .subBoxCon2-1 {
    font-weight: bold;
    font-size: 16px;
  }


  .socialIcons {
    display: flex;
    margin-left: auto;
    margin-bottom: 4%;
  }

  .socialIcons img {
    margin-right: 20px;
    width: 30px;
    height: 30px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .socialIcons img:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    cursor: pointer;
  }

  .footer {
    justify-content: space-between;
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
  }

  .boxWrap2Footer {
    background-color: rgba(0, 0, 0, 0);
  }

  .footer1 {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-top: 5%;
  }

  .line2 {
    width: 100%;
    height: 1.5px;
    background-color: white;
    margin-bottom: 30px;
  }

  .copyright {
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
    font-size: 15px;
    color: white;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .terms {
    display: flex;
  }

  .terms div {
    margin-left: 20px;
    color: rgba(255, 255, 255, 0.5);
    font-size: 15px;
    color: white;
    text-shadow: 0 0 10px #FFFFFF;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    padding-left: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-width: 3px;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #d4b500, #176300);
    border-image-slice: 1;
    -webkit-backdrop-filter: blur(5px);
    background: rgb(107, 215, 254);
    background: linear-gradient(90deg, rgba(107, 215, 254, 0.07886904761904767) 0%, rgba(199, 35, 163, 0.07046568627450978) 100%);
    margin-bottom: 2%;
    margin-top: 2%;
  }
  
  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 40s;
  }
  
  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 18px;
  }
  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 20px;
    color: white;
  }
  
  summary {
    font-size: 14px;
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: rgb(255, 255, 255);
    font-weight: 500;
    border: 1px solid rgba(255, 255, 255, 0.719);
    background-color: #2525258f;
  }
  
  details>summary::-webkit-details-marker {
    display: none;
  }
  
  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }
  
  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .faq__content {
    color: rgb(255, 255, 255);
    padding-bottom: 20px;
    text-align: left;
    font-size: 16px;
  }
  
  .submit{
    border: 2px solid white;
    background-color: #00000085;
    color: white;
    padding: 10px;
   }
  
  input{
    border: 2px solid white;
    background-color: #00000085;
    color: white;
    padding: 10px;
  } 

  .formMain-2 {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    z-index: 100000;
    display: flex;
    flex-flow: row nowrap;
    background-color: rgba(0, 0, 0, 0);
    padding: 50px;
    -webkit-animation: popupAnimation 0.5s ease forwards;
            animation: popupAnimation 0.5s ease forwards;
    border-radius: 10px;
  }

  .lds-grid {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .lds-grid div {
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #cef;
    -webkit-animation: lds-grid 1.2s linear infinite;
            animation: lds-grid 1.2s linear infinite;
  }

  .lds-grid div:nth-child(1) {
    top: 8px;
    left: 8px;
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
  }

  .lds-grid div:nth-child(2) {
    top: 8px;
    left: 32px;
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
  }

  .lds-grid div:nth-child(3) {
    top: 8px;
    left: 56px;
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
  }

  .lds-grid div:nth-child(4) {
    top: 32px;
    left: 8px;
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
  }

  .lds-grid div:nth-child(5) {
    top: 32px;
    left: 32px;
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
  }

  .lds-grid div:nth-child(6) {
    top: 32px;
    left: 56px;
    -webkit-animation-delay: -1.2s;
            animation-delay: -1.2s;
  }

  .lds-grid div:nth-child(7) {
    top: 56px;
    left: 8px;
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
  }

  .lds-grid div:nth-child(8) {
    top: 56px;
    left: 32px;
    -webkit-animation-delay: -1.2s;
            animation-delay: -1.2s;
  }

  .lds-grid div:nth-child(9) {
    top: 56px;
    left: 56px;
    -webkit-animation-delay: -1.6s;
            animation-delay: -1.6s;
  }

  @-webkit-keyframes lds-grid {

    0%,
    100% {
      opacity: 1;
    }

    50% {
      opacity: 0.5;
    }
  }

  @keyframes lds-grid {

    0%,
    100% {
      opacity: 1;
    }

    50% {
      opacity: 0.5;
    }
  }


  .success-message {
    color: #50ec30;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 24px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 18px;
    margin-bottom: 0;
  }

  .errorMessage {
    margin-top: 3%;
    font-size: 20px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    z-index: 10000000;
    color: white;
  }

  .loadText {
    text-align: center;
    color: #0fce0f;
    font-size: 20px;
    z-index: 100000000000000000;
  }

  .loadingText-2 {
    text-align: center;
    color: white;
    font-size: 18px;
    z-index: 100000000000000000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 20px;
  }

  .error-2 {
    text-align: center;
    color: #fc4741;
    font-size: 18px;
    z-index: 100000000000000000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 20px;
  }

  .success-2 {
    text-align: center;
    color: #50ec30;
    font-size: 18px;
    z-index: 100000000000000000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 20px;
  }

  .check {
    width: 100px;
    height: 100px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 30px;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    /*background-color: #0000009f;*/
    z-index: 1000000;
    box-shadow: 0 0 10px 5px #00853c5d;
    border-bottom: 1px solid rgba(255, 255, 255, 0.507);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);

  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 16px;
    font-family: 'JetBrains Mono', 'monospace';
    cursor: pointer;
    z-index: 10000;
    color: #ffffff;
    text-shadow: 0 0 10px #FFFFFF;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }

  #fontSize:hover {
    text-shadow: 0 0 20px #ff462d;
    color: #e2f3a5;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 50%;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 45px;
    margin-right: 45px;
  }

  .connectBtn {
    background-color: rgba(0, 0, 0, 0.863);
    padding: 10px;
    font-size: 16px;
    padding-left: 30px;
    padding-right: 30px;
    color: white;
    text-shadow: 0 0 10px #FFFFFF;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
    border: 1px solid rgba(255, 255, 255, 0.507);
    font-family: 'JetBrains Mono', monospace;
    margin-left: 20px;
    box-shadow: 0 0 20px 5px rgba(255, 217, 0, 0.329);
    border: 1px solid;
    border-image: linear-gradient(to bottom, gold, #998f00);
    border-image-slice: 1;
  }

  .socialIcon {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .socialIcon img {
    width: 28px;
    height: 28px;
    margin-left: 8px;
    margin-right: 8px;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
  }

  .walletTxt {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  .wallet {
    width: 30px;
    height: 30px;
  }

  .connectBtn:hover {
    text-shadow: 0 0 20px #ffffff;
    color: #c6f52c;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;

    justify-content: center;
  }

  .cont {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.2909335609243697) 0%, rgba(0, 0, 0, 0.6970960259103641) 49%, rgba(0, 0, 0, 0.8567598914565826) 100%), url(/static/media/bg.e0255528.jpg) no-repeat center center fixed;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.png') no-repeat center center fixed;*/
    background-size: cover;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
    /*animation: changeBackground 10s infinite;*/
    min-height: 100vh;
    height: max-content;
    background-size: cover;
  }

  .introduction {
    margin-bottom: 10vh;
    margin-top: 10vh;
  }

  .logo {
    width: 80px;
    height: 80px;
    border-radius: 100px;
    border: 1px rgba(255, 217, 0, 0.589) solid;
    box-shadow: 0 0 20px 5px rgba(255, 217, 0, 0.329);
  }


  .logoDiv {
    display: flex;
    justify-content: center;
  }

  .title {
    font-size: 100px;
    color: white;
    text-align: center;
    font-family: 'Anton', sans-serif;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    background: -webkit-linear-gradient(gold, #00ff88);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .subH {
    color: rgba(255, 255, 255, 0.671);
    text-align: center;
    margin-top: -10px;
    font-size: 20px;
    text-shadow: 0 0 10px #00853c;
    white-space: nowrap;
    overflow: hidden;
    border-right: 4px solid rgba(255, 255, 255, .75);
    position: relative;
    margin-left: auto;
    margin-right: auto;
  }


  .line-1 {
    position: relative;
    color: rgb(255, 255, 255);
    font-size: 20px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    border-right: 2px solid rgba(255, 255, 255, .75);
    width: 100%;
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    font-size: 80px;
    color: white;
    font-weight: bold;
    letter-spacing: 3px;
  }

  .anim-typewriter {
    -webkit-animation: typewriter 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
            animation: typewriter 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
  }

  @-webkit-keyframes blinkTextCursor {
    from {
      border-right-color: rgba(255, 255, 255, .75);
    }

    to {
      border-right-color: transparent;
    }
  }

  @keyframes blinkTextCursor {
    from {
      border-right-color: rgba(255, 255, 255, .75);
    }

    to {
      border-right-color: transparent;
    }
  }

  @-webkit-keyframes typewriter {
    from {
      width: 0;
    }

    to {
      width: 440px;
    }
  }

  @keyframes typewriter {
    from {
      width: 0;
    }

    to {
      width: 440px;
    }
  }

  .introLogo {
    position: relative;
    border-radius: 100%;
    width: 30%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    opacity: 0.15;
    -webkit-animation: fadeAnimation 3s ease-in-out infinite alternate;
            animation: fadeAnimation 3s ease-in-out infinite alternate;
    /* Add animation property */
  }

  @-webkit-keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @-webkit-keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }


  .mintDiv {
    padding: 20px;
    padding-top: 50px;
    padding-bottom: 50px;
    width: 45%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    border: 2px solid rgba(255, 255, 255, 0.575);
    background-color: #21181dbb;
    border: 2px #faac98 solid;
    -webkit-animation-name: glow-in-out;
            animation-name: glow-in-out;
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    border-radius: 50px;
  }


  .mintCon {
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 2px;
    z-index: 10000000;
    font-weight: 500;
  }

  .price {
    text-align: center;
    font-size: 30px;
    color: rgb(255, 255, 255);
    z-index: 10000000;
    letter-spacing: 3px;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    z-index: 10000000;
    width: 90%;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100000000000;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .btnfos-0-2 {
    font-size: 20px;
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 10px;
    z-index: 100000000;
    cursor: pointer;
    background-color: #3a9ae800;
    color: goldenrod;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 1px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
    color: white;
    border: #b1f11c 1px solid;
  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
  }

  .nftamount {
    color: goldenrod;
    font-size: 50px;
    font-family: 'Anton', sans-serif;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;
  }



  .connectButton {
    font-size: 18px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    text-shadow: 0 0 10px #FFFFFF;
    color: rgb(255, 255, 255);
    background-color: rgba(255, 217, 0, 0);
    width: 350px;
    padding: 15px;
    border: 2px white solid;
    margin-top: 8%;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(15px);
    border: 1px solid gold;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;

  }

  .connectButton:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    box-shadow: 0 0 15px 10px #00853c62;
  }

  .connectButton2 {
    font-size: 18px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    text-shadow: 0 0 10px #FFFFFF;
    color: rgb(255, 255, 255);
    background-color: rgba(255, 217, 0, 0);
    width: 350px;
    padding: 15px;
    border: 2px white solid;
    margin-top: 8%;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(15px);
    border: 1px solid gold;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
    width: 100%;
  }

  .connectButton2:hover {
    box-shadow: 0 0 15px 10px #00853c62;
  }

  .connectButton2:active {
    background-color: #00853c3a;
  }

  @-webkit-keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .boxWrap2MintMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-top: 3%;
    padding-bottom: 5%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.13687193627450978) 0%, rgba(0, 0, 0, 0.5766478466386555) 49%, rgba(0, 0, 0, 0.8007374824929971) 100%), url('https://media.discordapp.net/attachments/1008571037862080542/1132577262965882911/NFTHouse9_coins_floating_cyber_punk_theme_greenish_and_yellow_84ff95a2-ed2c-47c4-b751-199a85841236.png?width=843&height=473') no-repeat center center fixed;
    background-size: cover;
  }


  .boxWrap2Mint {
    display: flex;
    justify-content: center;
    margin-top: 3%;
    margin-bottom: 3%;
  }

  .box1 {
    background-color: white;
    margin-left: 30px;
    margin-right: 30px;
    background-color: #0005001f;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(5px);
    width: 40%;
    border: 1px solid rgba(255, 255, 255, 0.219);
    box-shadow: 0 0 10px 5px #83850062;
  }

  .boxH {
    color: white;
    font-family: 'Anton', sans-serif;
    color: gold;
    font-size: 25px;
    text-align: center;
  }

  .boxH2 {
    display: flex;
    justify-content: space-evenly;
    color: gold;
    font-size: 25px;
    font-family: 'Anton', sans-serif;
  }

  .txtH2 {
    font-family: 'Anton', sans-serif;
  }

  .subBoxMain {
    padding: 30px;
  }

  .subBoxMain2 {
    display: flex;
    justify-content: space-evenly;
    color: white;
    margin-top: 5%;
  }

  .subBox {
    border: 1px #ffffff34 solid;
    padding: 20px;
    width: 220px;
  }

  .subBoxCon1 {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.486);
  }

  .subBoxCon2 {
    font-size: 18px;
  }

  .timeMain {
    display: flex;
    color: white;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 15%;
  }

  .time {
    font-size: 50px;
    color: gold;
    font-weight: 500;
    background-color: #0c3a0ea4;
    width: 110px;
    border-radius: 10px;
  }

  .timeN {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.486);
  }

  .timeMain2 {
    margin-left: 20px;
    margin-right: 20px;
  }

  .subBox2 {
    display: flex;
    justify-content: space-evenly;
    color: white;
    border: 1px #ffffff34 solid;
    margin-top: 6%;
    margin-bottom: 6%;
  }

  .subBox3 {
    display: flex;
    justify-content: space-between;
    color: white;
    margin-top: 10px;
  }

  .subBoxCon2-1 {
    font-weight: bold;
    font-size: 16px;
  }


  .socialIcons {
    display: flex;
    margin-left: auto;
    margin-bottom: 4%;
  }

  .socialIcons img {
    margin-right: 20px;
    width: 30px;
    height: 30px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .socialIcons img:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    cursor: pointer;
  }

  .footer {
    justify-content: space-between;
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
  }

  .boxWrap2Footer {
    background-color: rgba(0, 0, 0, 0);
  }

  .footer1 {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-top: 5%;
  }

  .line2 {
    width: 100%;
    height: 1.5px;
    background-color: white;
    margin-bottom: 30px;
  }

  .copyright {
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
    font-size: 15px;
    color: white;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .terms {
    display: flex;
  }

  .terms div {
    margin-left: 20px;
    color: rgba(255, 255, 255, 0.5);
    font-size: 15px;
    color: white;
    text-shadow: 0 0 10px #FFFFFF;
  }
}

@media screen and (min-width: 1920px) {

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 6rem;
    padding-left: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-width: 3px;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #d4b500, #176300);
    border-image-slice: 1;
    -webkit-backdrop-filter: blur(5px);
    background: rgb(107, 215, 254);
    background: linear-gradient(90deg, rgba(107, 215, 254, 0.07886904761904767) 0%, rgba(199, 35, 163, 0.07046568627450978) 100%);
    margin-bottom: 2%;
    margin-top: 2%;
  }
  
  .ticker-wrap .ticker {
    display: inline-block;
    height: 6rem;
    line-height: 6rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 40s;
  }
  
  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 2rem;
    font-size: 25px;
  }
  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 30px;
    color: white;
  }
  
  summary {
    font-size: 18px;
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1.5rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: rgb(255, 255, 255);
    font-weight: 500;
    border: 1px solid rgba(255, 255, 255, 0.719);
    background-color: #2525258f;
  }
  
  details>summary::-webkit-details-marker {
    display: none;
  }
  
  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }
  
  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .faq__content {
    color: rgb(255, 255, 255);
    padding-bottom: 20px;
    text-align: left;
    font-size: 20px;
  }
  
  .submit{
    border: 2px solid white;
    background-color: #00000085;
    color: white;
    padding: 10px;
   }
  
  input{
    border: 2px solid white;
    background-color: #00000085;
    color: white;
    padding: 10px;
  } 
  .formMain-2 {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    z-index: 100000;
    display: flex;
    flex-flow: row nowrap;
    background-color: rgba(0, 0, 0, 0);
    padding: 50px;
    -webkit-animation: popupAnimation 0.5s ease forwards;
            animation: popupAnimation 0.5s ease forwards;
    border-radius: 10px;
  }

  .lds-grid {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .lds-grid div {
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #cef;
    -webkit-animation: lds-grid 1.2s linear infinite;
            animation: lds-grid 1.2s linear infinite;
  }

  .lds-grid div:nth-child(1) {
    top: 8px;
    left: 8px;
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
  }

  .lds-grid div:nth-child(2) {
    top: 8px;
    left: 32px;
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
  }

  .lds-grid div:nth-child(3) {
    top: 8px;
    left: 56px;
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
  }

  .lds-grid div:nth-child(4) {
    top: 32px;
    left: 8px;
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
  }

  .lds-grid div:nth-child(5) {
    top: 32px;
    left: 32px;
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
  }

  .lds-grid div:nth-child(6) {
    top: 32px;
    left: 56px;
    -webkit-animation-delay: -1.2s;
            animation-delay: -1.2s;
  }

  .lds-grid div:nth-child(7) {
    top: 56px;
    left: 8px;
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
  }

  .lds-grid div:nth-child(8) {
    top: 56px;
    left: 32px;
    -webkit-animation-delay: -1.2s;
            animation-delay: -1.2s;
  }

  .lds-grid div:nth-child(9) {
    top: 56px;
    left: 56px;
    -webkit-animation-delay: -1.6s;
            animation-delay: -1.6s;
  }

  @-webkit-keyframes lds-grid {

    0%,
    100% {
      opacity: 1;
    }

    50% {
      opacity: 0.5;
    }
  }

  @keyframes lds-grid {

    0%,
    100% {
      opacity: 1;
    }

    50% {
      opacity: 0.5;
    }
  }


  .success-message {
    color: #50ec30;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 34px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 24px;
    margin-bottom: 0;
  }

  .errorMessage {
    margin-top: 3%;
    font-size: 34px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    z-index: 10000000;
    color: white;
  }

  .loadText {
    text-align: center;
    color: #0fce0f;
    font-size: 20px;
    z-index: 100000000000000000;
  }

  .loadingText-2 {
    text-align: center;
    color: white;
    font-size: 18px;
    z-index: 100000000000000000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 20px;
  }

  .error-2 {
    text-align: center;
    color: #fc4741;
    font-size: 18px;
    z-index: 100000000000000000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 20px;
  }

  .success-2 {
    text-align: center;
    color: #50ec30;
    font-size: 18px;
    z-index: 100000000000000000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 20px;
  }

  .check {
    width: 150px;
    height: 150px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 30px;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    /*background-color: #0000009f;*/
    z-index: 1000000;
    box-shadow: 0 0 10px 5px #00853c5d;
    border-bottom: 1px solid rgba(255, 255, 255, 0.507);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);

  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 16px;
    font-family: 'JetBrains Mono', 'monospace';
    cursor: pointer;
    z-index: 10000;
    color: #ffffff;
    text-shadow: 0 0 10px #FFFFFF;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }

  #fontSize:hover {
    text-shadow: 0 0 20px #ff462d;
    color: #e2f3a5;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 50%;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 45px;
    margin-right: 45px;
  }

  .connectBtn {
    background-color: rgba(0, 0, 0, 0.863);
    padding: 15px;
    font-size: 24px;
    padding-left: 40px;
    padding-right: 40px;
    color: white;
    text-shadow: 0 0 10px #FFFFFF;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
    border: 1px solid rgba(255, 255, 255, 0.507);
    font-family: 'JetBrains Mono', monospace;
    margin-left: 20px;
    box-shadow: 0 0 20px 5px rgba(255, 217, 0, 0.329);
    border: 1px solid;
    border-image: linear-gradient(to bottom, gold, #998f00);
    border-image-slice: 1;
  }

  .socialIcon {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .socialIcon img {
    width: 40px;
    height: 40px;
    margin-left: 12px;
    margin-right: 12px;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
  }

  .walletTxt {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  .wallet {
    width: 30px;
    height: 30px;
  }

  .connectBtn:hover {
    text-shadow: 0 0 20px #ffffff;
    color: #c6f52c;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;

    justify-content: center;
  }

  .cont {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.2909335609243697) 0%, rgba(0, 0, 0, 0.6970960259103641) 49%, rgba(0, 0, 0, 0.8567598914565826) 100%), url(/static/media/bg.e0255528.jpg) no-repeat center center fixed;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.png') no-repeat center center fixed;*/
    background-size: cover;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
    /*animation: changeBackground 10s infinite;*/
    min-height: 100vh;
    height: max-content;
    background-size: cover;
  }

  .introduction {
    margin-bottom: 10vh;
    margin-top: 10vh;
  }

  .logo {
    width: 110px;
    height: 110px;
    border-radius: 100%;
    border: 2px rgba(255, 217, 0, 0.589) solid;
    box-shadow: 0 0 20px 5px rgba(255, 217, 0, 0.329);
  }


  .logoDiv {
    display: flex;
    justify-content: center;
  }

  .title {
    font-size: 150px;
    color: white;
    text-align: center;
    font-family: 'Anton', sans-serif;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    background: -webkit-linear-gradient(gold, #00ff88);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .subH {
    color: rgba(255, 255, 255, 0.671);
    text-align: center;
    margin-top: -10px;
    font-size: 30px;
    text-shadow: 0 0 10px #00853c;
    white-space: nowrap;
    overflow: hidden;
    border-right: 6px solid rgba(255, 255, 255, .75);
    position: relative;
    margin-left: auto;
    margin-right: auto;
  }


  .line-1 {
    position: relative;
    color: rgb(255, 255, 255);
    font-size: 20px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    border-right: 2px solid rgba(255, 255, 255, .75);
    width: 100%;
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    font-size: 80px;
    color: white;
    font-weight: bold;
    letter-spacing: 3px;
  }

  .anim-typewriter {
    -webkit-animation: typewriter 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
            animation: typewriter 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
  }

  @-webkit-keyframes blinkTextCursor {
    from {
      border-right-color: rgba(255, 255, 255, .75);
    }

    to {
      border-right-color: transparent;
    }
  }

  @keyframes blinkTextCursor {
    from {
      border-right-color: rgba(255, 255, 255, .75);
    }

    to {
      border-right-color: transparent;
    }
  }

  @-webkit-keyframes typewriter {
    from {
      width: 0;
    }

    to {
      width: 650px;
    }
  }

  @keyframes typewriter {
    from {
      width: 0;
    }

    to {
      width: 650px;
    }
  }

  .introLogo {
    position: relative;
    border-radius: 100%;
    width: 30%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    opacity: 0.15;
    -webkit-animation: fadeAnimation 3s ease-in-out infinite alternate;
            animation: fadeAnimation 3s ease-in-out infinite alternate;
    /* Add animation property */
  }

  @-webkit-keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @-webkit-keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }


  .mintDiv {
    padding: 20px;
    padding-top: 50px;
    padding-bottom: 50px;
    width: 45%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    border: 2px solid rgba(255, 255, 255, 0.575);
    background-color: #21181dbb;
    border: 2px #faac98 solid;
    -webkit-animation-name: glow-in-out;
            animation-name: glow-in-out;
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    border-radius: 50px;
  }


  .mintCon {
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 2px;
    z-index: 10000000;
    font-weight: 500;
  }

  .price {
    text-align: center;
    font-size: 30px;
    color: rgb(255, 255, 255);
    z-index: 10000000;
    letter-spacing: 3px;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    z-index: 10000000;
    width: 90%;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100000000000;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .btnfos-0-2 {
    font-size: 24px;
    width: 60px;
    height: 60px;
    border: none;
    border-radius: 10px;
    z-index: 100000000;
    cursor: pointer;
    background-color: #3a9ae800;
    color: goldenrod;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
    color: white;
    border: #b1f11c 2px solid;
  }

  .nftamount {
    color: goldenrod;
    font-size: 65px;
    font-family: 'Anton', sans-serif;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;
  }



  .connectButton {
    font-size: 25px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    text-shadow: 0 0 10px #FFFFFF;
    color: rgb(255, 255, 255);
    background-color: rgba(255, 217, 0, 0);
    width: 450px;
    padding: 20px;
    border: 4px white solid;
    margin-top: 8%;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(15px);
    border: 1px solid gold;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;

  }

  .connectButton:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    box-shadow: 0 0 15px 10px #00853c62;
  }

  .connectButton2 {
    font-size: 25px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    text-shadow: 0 0 10px #FFFFFF;
    color: rgb(255, 255, 255);
    background-color: rgba(255, 217, 0, 0);
    width: 90%;
    padding: 20px;
    border: 2px white solid;
    margin-top: 8%;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(15px);
    border: 2px solid gold;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
    width: 100%;
  }

  .connectButton2:hover {
    box-shadow: 0 0 15px 10px #00853c62;
  }

  .connectButton2:active {
    background-color: #00853c3a;
  }

  @-webkit-keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .boxWrap2MintMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-top: 3%;
    padding-bottom: 5%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.13687193627450978) 0%, rgba(0, 0, 0, 0.5766478466386555) 49%, rgba(0, 0, 0, 0.8007374824929971) 100%), url('https://media.discordapp.net/attachments/1008571037862080542/1132577262965882911/NFTHouse9_coins_floating_cyber_punk_theme_greenish_and_yellow_84ff95a2-ed2c-47c4-b751-199a85841236.png?width=843&height=473') no-repeat center center fixed;
    background-size: cover;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
  }


  .boxWrap2Mint {
    display: flex;
    justify-content: center;
    margin-top: 3%;
    margin-bottom: 3%;
  }

  .box1 {
    background-color: white;
    margin-left: 30px;
    margin-right: 30px;
    background-color: #0005001f;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(5px);
    width: 40%;
    border: 2px solid rgba(255, 255, 255, 0.219);
    box-shadow: 0 0 10px 5px #83850062;
    padding: 10px;
  }

  .boxH {
    color: white;
    font-family: 'Anton', sans-serif;
    color: gold;
    font-size: 35px;
    text-align: center;
  }

  .boxH2 {
    display: flex;
    justify-content: space-evenly;
    color: gold;
    font-size: 35px;
    font-family: 'Anton', sans-serif;
  }

  .txtH2 {
    font-family: 'Anton', sans-serif;
  }

  .subBoxMain {
    padding: 30px;
  }

  .subBoxMain2 {
    display: flex;
    justify-content: space-evenly;
    color: white;
    margin-top: 5%;
  }

  .subBox {
    border: 2px #ffffff34 solid;
    padding: 25px;
    width: 300px;
  }

  .subBoxCon1 {
    font-size: 24px;
    color: rgba(255, 255, 255, 0.486);
  }

  .subBoxCon2 {
    font-size: 25px;
  }

  .timeMain {
    display: flex;
    color: white;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 15%;
  }

  .time {
    font-size: 80px;
    color: gold;
    font-weight: 500;
    background-color: #0c3a0ea4;
    width: 180px;
    border-radius: 20px;
  }

  .timeN {
    font-size: 24px;
    color: rgba(255, 255, 255, 0.486);
  }

  .timeMain2 {
    margin-left: 20px;
    margin-right: 20px;
  }

  .subBox2 {
    display: flex;
    justify-content: space-evenly;
    color: white;
    border: 2px #ffffff34 solid;
    margin-top: 6%;
    margin-bottom: 6%;
    font-size: 24px;
  }

  .subBox3 {
    display: flex;
    justify-content: space-between;
    color: white;
    margin-top: 10px;
  }

  .subBoxCon2-1 {
    font-weight: bold;
    font-size: 24px;
  }


  .socialIcons {
    display: flex;
    margin-left: auto;
    margin-bottom: 4%;
  }

  .socialIcons img {
    margin-right: 20px;
    width: 30px;
    height: 30px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .socialIcons img:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    cursor: pointer;
  }

  .footer {
    justify-content: space-between;
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
  }

  .boxWrap2Footer {
    background-color: rgba(0, 0, 0, 0);
  }

  .footer1 {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-top: 5%;
  }

  .line2 {
    width: 100%;
    height: 1.5px;
    background-color: white;
    margin-bottom: 40px;
  }

  .copyright {
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
    font-size: 22px;
    color: white;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .terms {
    display: flex;
  }

  .terms div {
    margin-left: 20px;
    color: rgba(255, 255, 255, 0.5);
    font-size: 22px;
    color: white;
    text-shadow: 0 0 10px #FFFFFF;
  }
}

@media screen and (min-width: 1921px) and (max-width: 3839px) {
  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 8rem;
    padding-left: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-width: 5px;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #d4b500, #176300);
    border-image-slice: 1;
    -webkit-backdrop-filter: blur(5px);
    background: rgb(107, 215, 254);
    background: linear-gradient(90deg, rgba(107, 215, 254, 0.07886904761904767) 0%, rgba(199, 35, 163, 0.07046568627450978) 100%);
    margin-bottom: 2%;
    margin-top: 2%;
  }
  
  .ticker-wrap .ticker {
    display: inline-block;
    height: 8rem;
    line-height: 8rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 28s;
  }
  
  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 3rem;
    font-size: 35px;
  }
 
  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 40px;
    color: white;
  }
  
  summary {
    font-size: 25px;
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 1.5rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: rgb(255, 255, 255);
    font-weight: 500;
    border: 1px solid rgba(255, 255, 255, 0.719);
    background-color: #2525258f;
  }
  
  details>summary::-webkit-details-marker {
    display: none;
  }
  
  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }
  
  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .faq__content {
    color: rgb(255, 255, 255);
    padding-bottom: 20px;
    text-align: left;
    font-size: 28px;
  }
  
  .submit{
    border: 3px solid white;
    background-color: #00000085;
    color: white;
    padding: 15px;
   }
  
  input{
    border: 3px solid white;
    background-color: #00000085;
    color: white;
    padding: 15px;
  } 
  
  .formMain-2 {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    z-index: 100000;
    display: flex;
    flex-flow: row nowrap;
    background-color: rgba(0, 0, 0, 0);
    padding: 50px;
    -webkit-animation: popupAnimation 0.5s ease forwards;
            animation: popupAnimation 0.5s ease forwards;
    border-radius: 10px;
  }

  .lds-grid {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .lds-grid div {
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #cef;
    -webkit-animation: lds-grid 1.2s linear infinite;
            animation: lds-grid 1.2s linear infinite;
  }

  .lds-grid div:nth-child(1) {
    top: 8px;
    left: 8px;
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
  }

  .lds-grid div:nth-child(2) {
    top: 8px;
    left: 32px;
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
  }

  .lds-grid div:nth-child(3) {
    top: 8px;
    left: 56px;
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
  }

  .lds-grid div:nth-child(4) {
    top: 32px;
    left: 8px;
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
  }

  .lds-grid div:nth-child(5) {
    top: 32px;
    left: 32px;
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
  }

  .lds-grid div:nth-child(6) {
    top: 32px;
    left: 56px;
    -webkit-animation-delay: -1.2s;
            animation-delay: -1.2s;
  }

  .lds-grid div:nth-child(7) {
    top: 56px;
    left: 8px;
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
  }

  .lds-grid div:nth-child(8) {
    top: 56px;
    left: 32px;
    -webkit-animation-delay: -1.2s;
            animation-delay: -1.2s;
  }

  .lds-grid div:nth-child(9) {
    top: 56px;
    left: 56px;
    -webkit-animation-delay: -1.6s;
            animation-delay: -1.6s;
  }

  @-webkit-keyframes lds-grid {

    0%,
    100% {
      opacity: 1;
    }

    50% {
      opacity: 0.5;
    }
  }

  @keyframes lds-grid {

    0%,
    100% {
      opacity: 1;
    }

    50% {
      opacity: 0.5;
    }
  }


  .success-message {
    color: #50ec30;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 44px;
    margin-bottom: 10px;
  }

  .success-message p {
    font-size: 34px;
    margin-bottom: 0;
  }

  .errorMessage {
    margin-top: 3%;
    font-size: 44px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    z-index: 10000000;
    color: white;
  }

  .loadText {
    text-align: center;
    color: #0fce0f;
    font-size: 20px;
    z-index: 100000000000000000;
  }

  .loadingText-2 {
    text-align: center;
    color: white;
    font-size: 18px;
    z-index: 100000000000000000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 20px;
  }

  .error-2 {
    text-align: center;
    color: #fc4741;
    font-size: 18px;
    z-index: 100000000000000000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 20px;
  }

  .success-2 {
    text-align: center;
    color: #50ec30;
    font-size: 18px;
    z-index: 100000000000000000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 20px;
  }

  .check {
    width: 200px;
    height: 200px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 60px;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    /*background-color: #0000009f;*/
    z-index: 1000000;
    box-shadow: 0 0 10px 5px #00853c5d;
    border-bottom: 1px solid rgba(255, 255, 255, 0.507);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);

  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 16px;
    font-family: 'JetBrains Mono', 'monospace';
    cursor: pointer;
    z-index: 10000;
    color: #ffffff;
    text-shadow: 0 0 10px #FFFFFF;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }

  #fontSize:hover {
    text-shadow: 0 0 20px #ff462d;
    color: #e2f3a5;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 50%;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 45px;
    margin-right: 45px;
  }

  .connectBtn {
    background-color: rgba(0, 0, 0, 0.863);
    padding: 20px;
    font-size: 30px;
    padding-left: 60px;
    padding-right: 60px;
    color: white;
    text-shadow: 0 0 10px #FFFFFF;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
    border: 1px solid rgba(255, 255, 255, 0.507);
    font-family: 'JetBrains Mono', monospace;
    margin-left: 20px;
    box-shadow: 0 0 20px 5px rgba(255, 217, 0, 0.329);
    border: 1px solid;
    border-image: linear-gradient(to bottom, gold, #998f00);
    border-image-slice: 1;
    margin-left: 20px;
  }

  .socialIcon {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .socialIcon img {
    width: 57px;
    height: 57px;
    margin-left: 18px;
    margin-right: 18px;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
  }

  .walletTxt {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  .wallet {
    width: 30px;
    height: 30px;
  }

  .connectBtn:hover {
    text-shadow: 0 0 20px #ffffff;
    color: #c6f52c;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;

    justify-content: center;
  }

  .cont {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.2909335609243697) 0%, rgba(0, 0, 0, 0.6970960259103641) 49%, rgba(0, 0, 0, 0.8567598914565826) 100%), url(/static/media/bg.e0255528.jpg) no-repeat center center fixed;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.png') no-repeat center center fixed;*/
    background-size: cover;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
    /*animation: changeBackground 10s infinite;*/
    min-height: 100vh;
    height: max-content;
    background-size: cover;
  }

  .introduction {
    margin-bottom: 10vh;
    margin-top: 10vh;
  }

  .logo {
    width: 160px;
    height: 160px;
    border-radius: 100%;
    border: 3px rgba(255, 217, 0, 0.589) solid;
    box-shadow: 0 0 25px 10px rgba(255, 217, 0, 0.329);
  }


  .logoDiv {
    display: flex;
    justify-content: center;
  }

  .title {
    font-size: 200px;
    color: white;
    text-align: center;
    font-family: 'Anton', sans-serif;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    background: -webkit-linear-gradient(gold, #00ff88);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .subH {
    color: rgba(255, 255, 255, 0.671);
    text-align: center;
    margin-top: -10px;
    font-size: 43px;
    text-shadow: 0 0 10px #00853c;
    white-space: nowrap;
    overflow: hidden;
    border-right: 7px solid rgba(255, 255, 255, .75);
    position: relative;
    margin-left: auto;
    margin-right: auto;
  }


  .line-1 {
    position: relative;
    color: rgb(255, 255, 255);
    font-size: 20px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    border-right: 2px solid rgba(255, 255, 255, .75);
    width: 100%;
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    font-size: 80px;
    color: white;
    font-weight: bold;
    letter-spacing: 3px;
  }

  .anim-typewriter {
    -webkit-animation: typewriter 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
            animation: typewriter 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
  }

  @-webkit-keyframes blinkTextCursor {
    from {
      border-right-color: rgba(255, 255, 255, .75);
    }

    to {
      border-right-color: transparent;
    }
  }

  @keyframes blinkTextCursor {
    from {
      border-right-color: rgba(255, 255, 255, .75);
    }

    to {
      border-right-color: transparent;
    }
  }

  @-webkit-keyframes typewriter {
    from {
      width: 0;
    }

    to {
      width: 950px;
    }
  }

  @keyframes typewriter {
    from {
      width: 0;
    }

    to {
      width: 950px;
    }
  }

  .introLogo {
    position: relative;
    border-radius: 100%;
    width: 30%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    opacity: 0.15;
    -webkit-animation: fadeAnimation 3s ease-in-out infinite alternate;
            animation: fadeAnimation 3s ease-in-out infinite alternate;
    /* Add animation property */
  }

  @-webkit-keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @-webkit-keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }


  .mintDiv {
    padding: 20px;
    padding-top: 50px;
    padding-bottom: 50px;
    width: 45%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    border: 2px solid rgba(255, 255, 255, 0.575);
    background-color: #21181dbb;
    border: 2px #faac98 solid;
    -webkit-animation-name: glow-in-out;
            animation-name: glow-in-out;
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    border-radius: 50px;
  }


  .mintCon {
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 2px;
    z-index: 10000000;
    font-weight: 500;
  }

  .price {
    text-align: center;
    font-size: 30px;
    color: rgb(255, 255, 255);
    z-index: 10000000;
    letter-spacing: 3px;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    z-index: 10000000;
    width: 90%;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100000000000;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .btnfos-0-2 {
    font-size: 30px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 10px;
    z-index: 100000000;
    cursor: pointer;
    background-color: #3a9ae800;
    color: goldenrod;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 3px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
    color: white;
    border: #b1f11c 3px solid;
  }

  .nftamount {
    color: goldenrod;
    font-size: 80px;
    font-family: 'Anton', sans-serif;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;
  }



  .connectButton {
    font-size: 35px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    text-shadow: 0 0 10px #FFFFFF;
    color: rgb(255, 255, 255);
    background-color: rgba(255, 217, 0, 0);
    width: 650px;
    padding: 26px;
    border: 5px white solid;
    margin-top: 8%;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(15px);
    border: 2px solid gold;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;

  }

  .connectButton:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    box-shadow: 0 0 15px 10px #00853c62;
  }

  .connectButton2 {
    font-size: 35px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    text-shadow: 0 0 10px #FFFFFF;
    color: rgb(255, 255, 255);
    background-color: rgba(255, 217, 0, 0);
    width: 90%;
    padding: 30px;
    border: 3px white solid;
    margin-top: 8%;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(15px);
    border: 3px solid gold;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
    width: 100%;
  }

  .connectButton2:hover {
    box-shadow: 0 0 15px 10px #00853c62;
  }

  .connectButton2:active {
    background-color: #00853c3a;
  }

  @-webkit-keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .boxWrap2MintMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-top: 3%;
    padding-bottom: 5%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.13687193627450978) 0%, rgba(0, 0, 0, 0.5766478466386555) 49%, rgba(0, 0, 0, 0.8007374824929971) 100%), url('https://media.discordapp.net/attachments/1008571037862080542/1132577262965882911/NFTHouse9_coins_floating_cyber_punk_theme_greenish_and_yellow_84ff95a2-ed2c-47c4-b751-199a85841236.png?width=843&height=473') no-repeat center center fixed;
    background-size: cover;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
  }


  .boxWrap2Mint {
    display: flex;
    justify-content: center;
    margin-top: 3%;
    margin-bottom: 3%;
  }

  .box1 {
    background-color: white;
    margin-left: 30px;
    margin-right: 30px;
    background-color: #0005001f;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(5px);
    width: 40%;
    border: 3px solid rgba(255, 255, 255, 0.219);
    box-shadow: 0 0 10px 5px #83850062;
    padding: 10px;
  }

  .boxH {
    color: white;
    font-family: 'Anton', sans-serif;
    color: gold;
    font-size: 46px;
    text-align: center;
  }

  .boxH2 {
    display: flex;
    justify-content: space-evenly;
    color: gold;
    font-size: 46px;
    font-family: 'Anton', sans-serif;
  }

  .txtH2 {
    font-family: 'Anton', sans-serif;
  }

  .subBoxMain {
    padding: 40px;
  }

  .subBoxMain2 {
    display: flex;
    justify-content: space-evenly;
    color: white;
    margin-top: 5%;
  }

  .subBox {
    border: 3px #ffffff34 solid;
    padding: 30px;
    width: 400px;
  }

  .subBoxCon1 {
    font-size: 32px;
    color: rgba(255, 255, 255, 0.486);
  }

  .subBoxCon2 {
    font-size: 32px;
  }

  .timeMain {
    display: flex;
    color: white;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 15%;
  }

  .time {
    font-size: 90px;
    color: gold;
    font-weight: 500;
    background-color: #0c3a0ea4;
    width: 220px;
    border-radius: 20px;
  }

  .timeN {
    font-size: 35px;
    color: rgba(255, 255, 255, 0.486);
  }

  .timeMain2 {
    margin-left: 50px;
    margin-right: 50px;
  }

  .subBox2 {
    display: flex;
    justify-content: space-evenly;
    color: white;
    border: 3px #ffffff34 solid;
    margin-top: 6%;
    margin-bottom: 6%;
    font-size: 35px;
  }

  .subBox3 {
    display: flex;
    justify-content: space-between;
    color: white;
    margin-top: 10px;
  }

  .subBoxCon2-1 {
    font-weight: bold;
    font-size: 35px;
  }


  .socialIcons {
    display: flex;
    margin-left: auto;
    margin-bottom: 4%;
  }

  .socialIcons img {
    margin-right: 20px;
    width: 30px;
    height: 30px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .socialIcons img:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    cursor: pointer;
  }

  .footer {
    justify-content: space-between;
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
  }

  .boxWrap2Footer {
    background-color: rgba(0, 0, 0, 0);
  }

  .footer1 {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-top: 5%;
  }

  .line2 {
    width: 100%;
    height: 1.5px;
    background-color: white;
    margin-bottom: 70px;
  }

  .copyright {
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
    font-size: 26px;
    color: white;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .terms {
    display: flex;
  }

  .terms div {
    margin-left: 20px;
    color: rgba(255, 255, 255, 0.5);
    font-size: 26px;
    color: white;
    text-shadow: 0 0 10px #FFFFFF;
  }
}

@media screen and (min-width: 3840px) {
  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 12rem;
    padding-left: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-width: 8px;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #d4b500, #176300);
    border-image-slice: 1;
    -webkit-backdrop-filter: blur(5px);
    background: rgb(107, 215, 254);
    background: linear-gradient(90deg, rgba(107, 215, 254, 0.07886904761904767) 0%, rgba(199, 35, 163, 0.07046568627450978) 100%);
    margin-bottom: 2%;
    margin-top: 2%;
  }
  
  .ticker-wrap .ticker {
    display: inline-block;
    height: 12rem;
    line-height: 12rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 28s;
  }
  
  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 3rem;
    font-size: 55px;
  }
 
 
  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 60px;
    color: white;
  }
  
  summary {
    font-size: 45px;
    color: rgb(0, 0, 0);
    padding: 2rem;
    margin-bottom: 2.5rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: rgb(255, 255, 255);
    font-weight: 500;
    border: 1px solid rgba(255, 255, 255, 0.719);
    background-color: #2525258f;
  }
  
  details>summary::-webkit-details-marker {
    display: none;
  }
  
  details[open] summary~* {
    -webkit-animation: sweep .5s ease-in-out;
            animation: sweep .5s ease-in-out;
  }
  
  @-webkit-keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .faq__content {
    color: rgb(255, 255, 255);
    padding-bottom: 40px;
    text-align: left;
    font-size: 50px;
  }
  
  .submit{
    border: 5px solid white;
    background-color: #00000085;
    color: white;
    padding: 25px;
   }
  
  input{
    border: 5px solid white;
    background-color: #00000085;
    color: white;
    padding: 25px;
  } 
  
 
  .formMain-2 {
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    top: 50%;
    left: 50%;
    z-index: 1;
    overflow: scroll;
    overflow-x: hidden;
    z-index: 100000;
    display: flex;
    flex-flow: row nowrap;
    background-color: rgba(0, 0, 0, 0);
    padding: 50px;
    -webkit-animation: popupAnimation 0.5s ease forwards;
            animation: popupAnimation 0.5s ease forwards;
    border-radius: 10px;
  }

  .lds-grid {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .lds-grid div {
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #cef;
    -webkit-animation: lds-grid 1.2s linear infinite;
            animation: lds-grid 1.2s linear infinite;
  }

  .lds-grid div:nth-child(1) {
    top: 8px;
    left: 8px;
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
  }

  .lds-grid div:nth-child(2) {
    top: 8px;
    left: 32px;
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
  }

  .lds-grid div:nth-child(3) {
    top: 8px;
    left: 56px;
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
  }

  .lds-grid div:nth-child(4) {
    top: 32px;
    left: 8px;
    -webkit-animation-delay: -0.4s;
            animation-delay: -0.4s;
  }

  .lds-grid div:nth-child(5) {
    top: 32px;
    left: 32px;
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
  }

  .lds-grid div:nth-child(6) {
    top: 32px;
    left: 56px;
    -webkit-animation-delay: -1.2s;
            animation-delay: -1.2s;
  }

  .lds-grid div:nth-child(7) {
    top: 56px;
    left: 8px;
    -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
  }

  .lds-grid div:nth-child(8) {
    top: 56px;
    left: 32px;
    -webkit-animation-delay: -1.2s;
            animation-delay: -1.2s;
  }

  .lds-grid div:nth-child(9) {
    top: 56px;
    left: 56px;
    -webkit-animation-delay: -1.6s;
            animation-delay: -1.6s;
  }

  @-webkit-keyframes lds-grid {

    0%,
    100% {
      opacity: 1;
    }

    50% {
      opacity: 0.5;
    }
  }

  @keyframes lds-grid {

    0%,
    100% {
      opacity: 1;
    }

    50% {
      opacity: 0.5;
    }
  }


  .success-message {
    color: #50ec30;
    border-radius: 5px;
    text-align: center;
  }

  .success-message h2 {
    font-size: 64px;
    margin-bottom: 10px;
  }

  .success-message p {
    font-size: 54px;
    margin-bottom: 0;
  }

  .errorMessage {
    margin-top: 3%;
    font-size: 64px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    z-index: 10000000;
    color: white;
  }

  .loadText {
    text-align: center;
    color: #0fce0f;
    font-size: 20px;
    z-index: 100000000000000000;
  }

  .loadingText-2 {
    text-align: center;
    color: white;
    font-size: 18px;
    z-index: 100000000000000000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 20px;
  }

  .error-2 {
    text-align: center;
    color: #fc4741;
    font-size: 18px;
    z-index: 100000000000000000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 20px;
  }

  .success-2 {
    text-align: center;
    color: #50ec30;
    font-size: 18px;
    z-index: 100000000000000000;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 20px;
  }

  .check {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 80px;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    /*background-color: #0000009f;*/
    z-index: 1000000;
    box-shadow: 0 0 10px 5px #00853c5d;
    border-bottom: 1px solid rgba(255, 255, 255, 0.507);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);

  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 16px;
    font-family: 'JetBrains Mono', 'monospace';
    cursor: pointer;
    z-index: 10000;
    color: #ffffff;
    text-shadow: 0 0 10px #FFFFFF;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }

  #fontSize:hover {
    text-shadow: 0 0 20px #ff462d;
    color: #e2f3a5;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 50%;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 45px;
    margin-right: 45px;
  }

  .connectBtn {
    background-color: rgba(0, 0, 0, 0.863);
    padding: 30px;
    font-size: 45px;
    padding-left: 70px;
    padding-right: 70px;
    color: white;
    text-shadow: 0 0 10px #FFFFFF;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    transition: 0.2s ease-in-out;
    border: 1px solid rgba(255, 255, 255, 0.507);
    font-family: 'JetBrains Mono', monospace;
    margin-left: 20px;
    box-shadow: 0 0 20px 5px rgba(255, 217, 0, 0.329);
    border: 1px solid;
    border-image: linear-gradient(to bottom, gold, #998f00);
    border-image-slice: 1;
    margin-left: 20px;
  }

  .socialIcon {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }

  .socialIcon img {
    width: 75px;
    height: 75px;
    margin-left: 25px;
    margin-right: 25px;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
  }

  .walletTxt {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }

  .wallet {
    width: 30px;
    height: 30px;
  }

  .connectBtn:hover {
    text-shadow: 0 0 20px #ffffff;
    color: #c6f52c;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;

    justify-content: center;
  }

  .cont {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.2909335609243697) 0%, rgba(0, 0, 0, 0.6970960259103641) 49%, rgba(0, 0, 0, 0.8567598914565826) 100%), url(/static/media/bg.e0255528.jpg) no-repeat center center fixed;
    /*background: linear-gradient(180deg, rgba(0,0,0,0.7203256302521008) 0%, rgba(0,0,0,0.2497373949579832) 50%, rgba(0,0,0,0.742734593837535) 100%), url('./assets/footer.png') no-repeat center center fixed;*/
    background-size: cover;
    height: -webkit-max-content;
    height: max-content;
    overflow: hidden;
    /*animation: changeBackground 10s infinite;*/
    min-height: 100vh;
    height: max-content;
    background-size: cover;
  }

  .introduction {
    margin-bottom: 10vh;
    margin-top: 10vh;
  }

  .logo {
    width: 230px;
    height: 230px;
    border-radius: 100%;
    border: 3px rgba(255, 217, 0, 0.589) solid;
    box-shadow: 0 0 25px 10px rgba(255, 217, 0, 0.329);
  }


  .logoDiv {
    display: flex;
    justify-content: center;
  }

  .title {
    font-size: 300px;
    color: white;
    text-align: center;
    font-family: 'Anton', sans-serif;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    background: -webkit-linear-gradient(gold, #00ff88);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .subH {
    color: rgba(255, 255, 255, 0.671);
    text-align: center;
    margin-top: -10px;
    font-size: 60px;
    text-shadow: 0 0 10px #00853c;
    white-space: nowrap;
    overflow: hidden;
    border-right: 8px solid rgba(255, 255, 255, .75);
    position: relative;
    margin-left: auto;
    margin-right: auto;
  }


  .line-1 {
    position: relative;
    color: rgb(255, 255, 255);
    font-size: 20px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    border-right: 2px solid rgba(255, 255, 255, .75);
    width: 100%;
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    font-size: 80px;
    color: white;
    font-weight: bold;
    letter-spacing: 3px;
  }

  .anim-typewriter {
    -webkit-animation: typewriter 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
            animation: typewriter 4s steps(44) 1s 1 normal both,
      blinkTextCursor 500ms steps(44) infinite normal;
  }

  @-webkit-keyframes blinkTextCursor {
    from {
      border-right-color: rgba(255, 255, 255, .75);
    }

    to {
      border-right-color: transparent;
    }
  }

  @keyframes blinkTextCursor {
    from {
      border-right-color: rgba(255, 255, 255, .75);
    }

    to {
      border-right-color: transparent;
    }
  }

  @-webkit-keyframes typewriter {
    from {
      width: 0;
    }

    to {
      width: 1330px;
    }
  }

  @keyframes typewriter {
    from {
      width: 0;
    }

    to {
      width: 1330px;
    }
  }

  .introLogo {
    position: relative;
    border-radius: 100%;
    width: 30%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    opacity: 0.15;
    -webkit-animation: fadeAnimation 3s ease-in-out infinite alternate;
            animation: fadeAnimation 3s ease-in-out infinite alternate;
    /* Add animation property */
  }

  @-webkit-keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @keyframes fadeAnimation {
    0% {
      opacity: 0.15;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.15;
    }
  }

  @-webkit-keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  @keyframes floating {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }

    50% {
      -webkit-transform: translateY(-20px);
              transform: translateY(-20px);
    }

    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }


  .mintDiv {
    padding: 20px;
    padding-top: 50px;
    padding-bottom: 50px;
    width: 45%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    border: 2px solid rgba(255, 255, 255, 0.575);
    background-color: #21181dbb;
    border: 2px #faac98 solid;
    -webkit-animation-name: glow-in-out;
            animation-name: glow-in-out;
    -webkit-animation-duration: 2s;
            animation-duration: 2s;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    border-radius: 50px;
  }


  .mintCon {
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 70%;
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 2px;
    z-index: 10000000;
    font-weight: 500;
  }

  .price {
    text-align: center;
    font-size: 30px;
    color: rgb(255, 255, 255);
    z-index: 10000000;
    letter-spacing: 3px;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    z-index: 10000000;
    width: 90%;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100000000000;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .btnfos-0-2 {
    font-size: 40px;
    width: 90px;
    height: 90px;
    border: none;
    border-radius: 20px;
    z-index: 100000000;
    cursor: pointer;
    background-color: #3a9ae800;
    color: goldenrod;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 6px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
    color: white;
    border: #b1f11c 6px solid;
  }

  .nftamount {
    color: goldenrod;
    font-size: 110px;
    font-family: 'Anton', sans-serif;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;
  }



  .connectButton {
    font-size: 48px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    text-shadow: 0 0 10px #FFFFFF;
    color: rgb(255, 255, 255);
    background-color: rgba(255, 217, 0, 0);
    width: 900px;
    padding: 40px;
    border: 5px white solid;
    margin-top: 8%;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(15px);
    border: 2px solid gold;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;

  }

  .connectButton:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    box-shadow: 0 0 15px 10px #00853c62;
  }

  .connectButton2 {
    font-size: 45px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    text-shadow: 0 0 10px #FFFFFF;
    color: rgb(255, 255, 255);
    background-color: rgba(255, 217, 0, 0);
    width: 90%;
    padding: 40px;
    border: 3px white solid;
    margin-top: 8%;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(15px);
    border: 3px solid gold;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
    width: 100%;
  }

  .connectButton2:hover {
    box-shadow: 0 0 15px 10px #00853c62;
  }

  .connectButton2:active {
    background-color: #00853c3a;
  }

  @-webkit-keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }

  .boxWrap2MintMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-top: 3%;
    padding-bottom: 5%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.13687193627450978) 0%, rgba(0, 0, 0, 0.5766478466386555) 49%, rgba(0, 0, 0, 0.8007374824929971) 100%), url('https://media.discordapp.net/attachments/1008571037862080542/1132577262965882911/NFTHouse9_coins_floating_cyber_punk_theme_greenish_and_yellow_84ff95a2-ed2c-47c4-b751-199a85841236.png?width=843&height=473') no-repeat center center fixed;
    background-size: cover;
    height: 100vh;
    min-height: 100vh;
    height: -webkit-max-content;
    height: max-content;
  }


  .boxWrap2Mint {
    display: flex;
    justify-content: center;
    margin-top: 3%;
    margin-bottom: 3%;
  }

  .box1 {
    background-color: white;
    margin-left: 30px;
    margin-right: 30px;
    background-color: #0005001f;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(5px);
    width: 40%;
    border: 3px solid rgba(255, 255, 255, 0.219);
    box-shadow: 0 0 10px 5px #83850062;
    padding: 10px;
  }

  .boxH {
    color: white;
    font-family: 'Anton', sans-serif;
    color: gold;
    font-size: 75px;
    text-align: center;
  }

  .boxH2 {
    display: flex;
    justify-content: space-evenly;
    color: gold;
    font-size: 75px;
    font-family: 'Anton', sans-serif;
  }

  .txtH2 {
    font-family: 'Anton', sans-serif;
  }

  .subBoxMain {
    padding: 50px;
  }

  .subBoxMain2 {
    display: flex;
    justify-content: space-evenly;
    color: white;
    margin-top: 5%;
  }

  .subBox {
    border: 4px #ffffff34 solid;
    padding: 40px;
    width: 600px;
  }

  .subBoxCon1 {
    font-size: 48px;
    color: rgba(255, 255, 255, 0.486);
  }

  .subBoxCon2 {
    font-size: 48px;
  }

  .timeMain {
    display: flex;
    color: white;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 15%;
  }

  .time {
    font-size: 130px;
    color: gold;
    font-weight: 500;
    background-color: #0c3a0ea4;
    width: 350px;
    border-radius: 20px;
  }

  .timeN {
    font-size: 45px;
    color: rgba(255, 255, 255, 0.486);
  }

  .timeMain2 {
    margin-left: 50px;
    margin-right: 50px;
  }

  .subBox2 {
    display: flex;
    justify-content: space-evenly;
    color: white;
    border: 4px #ffffff34 solid;
    margin-top: 6%;
    margin-bottom: 6%;
    font-size: 45px;
  }

  .subBox3 {
    display: flex;
    justify-content: space-between;
    color: white;
    margin-top: 10px;
  }

  .subBoxCon2-1 {
    font-weight: bold;
    font-size: 45px;
  }


  .socialIcons {
    display: flex;
    margin-left: auto;
    margin-bottom: 4%;
  }

  .socialIcons img {
    margin-right: 20px;
    width: 33px;
    height: 33px;
    transition: -webkit-transform .2s;
    transition: transform .2s;
    transition: transform .2s, -webkit-transform .2s;
  }

  .socialIcons img:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    cursor: pointer;
  }

  .footer {
    justify-content: space-between;
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
  }

  .boxWrap2Footer {
    background-color: rgba(0, 0, 0, 0);
  }

  .footer1 {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-top: 5%;
  }

  .line2 {
    width: 100%;
    height: 1.5px;
    background-color: white;
    margin-bottom: 100px;
  }

  .copyright {
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
    font-size: 45px;
    color: white;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .terms {
    display: flex;
  }

  .terms div {
    margin-left: 20px;
    color: rgba(255, 255, 255, 0.5);
    font-size: 45px;
    color: white;
    text-shadow: 0 0 10px #FFFFFF;
  }
}
